import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField } from '@material-ui/core'

const FormContaPagarReceber = ({ dados, updateField, planoContas, planosConta, updateFieldPlano, logado,
  formasPagamento, verificaDataParcela, updateFieldParcela, mudarParcelas, recalcularParcela, 
  verificaCaixaInicial, contasFinanceira, habilitaContasFinan }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">

      {verificaCaixaInicial()}

      <Grid item md={2} xs={12} sm={6}>
        {/* <TextField
          id="standard-select-currency"
          select
          label="Tipo da Conta"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="contpr_tipo"
          value={dados.contpr_tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">Selecionar Tipo</option>
          {logado === 'contas-pagar-receber' &&
            <option value="PAGAR">A Pagar</option>
          }
          <option value="RECEBER">A Receber</option>
        </TextField> */}
        <TextField className="input" label="Tipo da Conta*" variant="outlined" name="contpr_tipo" value={dados.contpr_tipo === '' ? '' : (dados.contpr_tipo === 'PAGAR' ? 'A Pagar' : 'A Receber')} InputLabelProps={{ shrink: true }} disabled/>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldPlano(event, value)}
          freeSolo
          options={planosConta}
          value={planoContas}
          getOptionLabel={option => option.plancontas_descricao}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} className="input" label="Plano de contas*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={planoContas.plancontas_descricao} />
          )}
        />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Descrição*" variant="outlined" name="contpr_descricao" value={dados.contpr_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Valor*" variant="outlined" name="contpr_valortotal" value={dados.contpr_valortotal} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
    </Grid>
    {dados.contpr_planocontas_id !== '' &&
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12} sm={12}>
          <div className='scroll'>
            <table className="lista sempadding borderTable">
              <thead>
                <tr className="titulos acompanha">
                  <th>Parcelas</th>
                  <th>Valor*</th>
                  <th>Vencimento*</th>
                  <th>Forma de Pagamento*</th>
                  {habilitaContasFinan &&
                    <th>Conta Financeira</th>
                  }
                  <th>Documento</th>
                </tr>
              </thead>
              <tbody>
                {dados.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                  return (
                    <tr key={parcela.index}>
                      <td>
                        <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                        <span className="separacaoParcelas">/</span>
                        <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={dados.qtd_parcelas} onChange={(e) => updateField(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                      </td>
                      <td>
                        <TextField className="inputValor" variant="outlined" size="small" name="parcont_valorparcela" value={parcela.parcont_valorparcela} onBlur={(e) => recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                      </td>
                      <td>
                        <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="parcont_datavencimento" value={parcela.parcont_datavencimento} onBlur={(e) => verificaDataParcela(e, parcela.index)} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                      </td>
                      <td>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="inputFormPagamento"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="parcont_formapagto_id"
                          value={parcela.parcont_formapagto_id}
                          onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value="">--Escolha--</option>
                          {formasPagamento.map(forma => {
                            return (
                              <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                            )
                          })}
                        </TextField>
                      </td>
                      {habilitaContasFinan &&
                        <td>
                          <TextField
                            id="standard-select-currency"
                            select
                            variant="outlined"
                            className="inputConta"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="contfin_id"
                            value={parcela.contfin_id}
                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                            InputLabelProps={{ shrink: true }}
                            disabled={parcela.parcont_formapagto_id === ''}
                          >
                            <option value="">--Escolha--</option>
                            {contasFinanceira.filter(param => param.contfin_tipo_conta === (parseInt(parcela.parcont_formapagto_id) === 1 ? 'COFRE': 'BANCO')).map(conta => {
                              return (
                                <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
                              )
                            })}
                          </TextField>
                        </td>
                      }
                      <td>
                        <TextField className="inputDocumento" variant="outlined" size="small" name="parcont_documento" value={parcela.parcont_documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    }
    <br/>
  </React.Fragment>
)

export default FormContaPagarReceber