import React, { Component, useRef } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import { saveAs } from 'file-saver';
import ModalAssinarContrato from './../../components/modals/AssinarContrato'
import Alert from './../../components/templates/Alert'
import Rodape from './../../components/RodapeLogin'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  codigo: '',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  contrato: {
    contm_id: '',
    contm_codigo: '',
    stcontmat_situacao: 'AGUARDANDO_ASSINATURA',
    resp_fin: '',
    documento: {
      contaluno_criado_em: ''
    }
  },
  aluno: {
    aluno_matricula: '',
    pesf_nome: ''
  },
  openModalAssinarContrato: false,
  localizacao: '',
  loading: true,
  travaBotao: false
}

let sigCanvas = {}

export default class ViewAluno extends Component {

  state = { ...initalState }
  
  getToken() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
      
            this.setState({
              localizacao: `${latitude}, ${longitude}`
            })
          },
          (error) => {
            this.setState({
              localizacao: 'Localização não compartilhada pelo usuário'
            })
          }
        );
      }else {
        this.setState({
          localizacao: 'Localização não compartilhada pelo usuário'
        })
      }

      const { match: { params } } = this.props;

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/contratoMatriculaAssinatura/${params.contrato_id}`, this.getToken())

      let resp_fin = null
      
      if(data.resp_fin){
        resp_fin = data.resp_fin
      }

      this.setState({
        contrato: {
          contm_id: data.contm_id,
          contm_codigo: data.contm_codigo,
          stcontmat_situacao: data.status.stcontmat_situacao,
          documento: data.documento,
          resp_fin
        }
      })

      const { data: aluno } = await axios.get(`${process.env.REACT_APP_API_URL}/alunoAssinatura/${data.contm_aluno_id}`, this.getToken())

      this.setState({
        aluno: {
          aluno_matricula: aluno.aluno_matricula,
          ...aluno.fisica
        }
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false
      })
      return
    }

    this.setState({
      loading: false
    })
  }
  
  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 4000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/aluno");
    }, timeout)
    this.setState({
      aula: initalState.aula,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateField(event){
    this.setState({
      codigo: event.target.value
    })
  }

  async baixarContrato(){
    try {
      const {codigo, contrato} = this.state

      if(codigo === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o código'
          },
          loadingSalvar: false
        })
        return
      }

      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 10000,
          severity: 'info',
          message: 'Baixando Contrato...'
        }
      })
      
      const { match: { params } } = this.props;
  
      const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/imprimirContratoAssinatura/${params.contrato_id}`, {codigo}, this.getToken())

      const dadosImprimir = { path: respImprimir.data }
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `Contrato-Aluno.pdf`); 

      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 3000,
          severity: 'success',
          message: 'Contrato Baixando'
        }
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false
      })
      return
    }
  }

  async assinarContrato(value, sigCanvas){
    if(value){
      this.setState({travaBotao: true})
      try {
        const {codigo, contrato, localizacao} = this.state
     
        let base64Assinatura = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        
        if(codigo === '') {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o código'
            },
            travaBotao: false
          })
          return
        }

        if(sigCanvas.current.isEmpty()) {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor escrever a sua assinatura'
            },
            travaBotao: false
          })
          return
        }

        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 10000,
            severity: 'info',
            message: 'Assinando Contrato...'
          }
        })
  
        const { match: { params } } = this.props;
  
        const res = await axios.get("https://api.ipify.org/?format=json");
        
        let dados = {
          contrato: params.contrato_id,
          codigo,
          base64Assinatura,
          localizacao: localizacao,
          ip: res.data.ip
        }

        const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/assinarContrato`, dados, this.getToken())
  
        const dadosImprimir = { path: respImprimir.data }
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Contrato-Aluno-Assinado.pdf`); 
  
        contrato.stcontmat_situacao = 'APROVADO'
  
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'success',
            message: 'Contrato Assinado com sucesso'
          },
          codigo: '',
          loadingSalvar: false,
          openModalAssinarContrato: false,
          contrato,
          travaBotao: false
        })
        return
  
      } catch ({response}) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          },
          loadingSalvar: false,
          travaBotao: false
        })
        return
      }
    }else{
      this.handleCloseModalAssinarContrato()
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  openModalAssinarContrato(){
    this.setState({
      openModalAssinarContrato: true
    })
  }

  handleCloseModalAssinarContrato(){
    this.setState({
      openModalAssinarContrato: false,
      travaBotao: false
    })
  }

  render() {
    const { codigo, contrato, aluno, loading } = this.state

    console.log(contrato.stcontmat_situacao)

    return (
      <div className="container-boletos" id="app">
        {loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados de Contrato...</p>
            </div>
          </React.Fragment>
        }
        {!loading &&
          <React.Fragment>
            {contrato.stcontmat_situacao === "AGUARDANDO_ASSINATURA" &&
              <React.Fragment>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                    <p>Olá {contrato.resp_fin ? contrato.resp_fin.pesf_nome : aluno.pesf_nome}, segue a baixo {contrato.resp_fin ? ` o contrato do aluno ${aluno.pesf_nome}` : ` o seu contrato`} Cod.: {contrato.contm_codigo}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item md={5} xs={12} sm={4}></Grid>
                  <Grid item md={2} xs={12} sm={4} >
                    <Button fullWidth variant="contained" color="primary" onClick={() => this.openModalAssinarContrato()}>
                      Assinar Contrato
                    </Button>
                  </Grid>
                  <Grid item md={5} xs={12} sm={4}></Grid>
                </Grid>
              </React.Fragment>
            }
    
            {contrato.stcontmat_situacao === "AGUARDANDO_APROVACAO" &&
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                  <p>Olá {aluno.pesf_nome}, esse contrato ainda não foi aprovado pela escola.</p>
                </Grid>
              </Grid>
            }
    
            {contrato.stcontmat_situacao === "CANCELADO" &&
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                  <p>Olá {aluno.pesf_nome}, esse contrato foi cancelado pela escola.</p>
                </Grid>
              </Grid>
            }

            {contrato.stcontmat_situacao === "APROVADO" &&
              <React.Fragment>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                    <p>Olá {aluno.pesf_nome}, o contrato de Cod.: {contrato.contm_codigo}, já foi assinado, data da assinatura: {moment(contrato.documento.contaluno_criado_em).format('DD/MM/YYYY HH:mm')}.</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item md={4} xs={12} sm={4} ></Grid>
                  <Grid item md={2} xs={12} sm={4} >
                    <TextField
                      className="input" 
                      label="Código Recebido por E-mail*" 
                      variant="outlined" 
                      size="small" 
                      name="codigo"
                      value={codigo} 
                      onChange={(e) => this.updateField(e)} 
                      InputLabelProps={{ shrink: true }} 
                      inputProps={{ maxLength: 6 }}
                    />   
                  </Grid>
                  <Grid item md={2} xs={12} sm={4} >
                    <Button style={{marginTop: 7}} fullWidth variant="contained" color="secondary" onClick={() => this.baixarContrato()}>
                      Baixar Contrato
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }

            {(contrato.stcontmat_situacao !== "AGUARDANDO_ASSINATURA" && contrato.stcontmat_situacao !== "APROVADO" && contrato.stcontmat_situacao !== "CANCELADO" && contrato.stcontmat_situacao !== "AGUARDANDO_APROVACAO") &&
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                  <p>Olá {aluno.pesf_nome}, esse contrato não pode ser assinado.</p>
                </Grid>
              </Grid>
            }
          </React.Fragment>
        }



        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12} sm={12}>
            <img className='imagemLogo' src="https://system-british-no-delete.s3.amazonaws.com/logo.png" alt="logo" />
          </Grid>
        </Grid>
        <Rodape />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAssinarContrato
          open={this.state.openModalAssinarContrato}
          handleClose={e => this.handleCloseModalAssinarContrato()} 
          contrato={contrato}
          aluno={aluno}
          codigo={codigo}
          updateField={(e) => this.updateField(e)}
          confirmar={(e, sigCanvas) => this.assinarContrato(e, sigCanvas)}
          limpar={(e) => this.limpar(e)}
          baixarContrato={() => this.baixarContrato()}
          sigCanvas={sigCanvas}
          travaBotao={this.state.travaBotao}
        />
      </div>
    )
  }
}