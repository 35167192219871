import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { Grid, TextField, FormControlLabel, Switch, Button } from '@material-ui/core'
import MaskedInput from 'react-text-mask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+',/\d/,/\d/,' ','(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const FormContato = ({ dados, updateField, updateFieldPrincipal, removerItem, disabledDados }) => (
  <React.Fragment>
    <Grid container direction="row">
      <Grid item md={12} xs={12} sm={12}>
        <h4>Contato {dados.index}</h4>
      </Grid>
    </Grid>

    <Grid
      container
      spacing={1}
      direction="row"
    >
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          fullWidth
          className="input"
          label="Número*"
          variant="outlined"
          size="small"
          name="numero"
          value={dados.numero}
          onChange={(e) => updateField(e)} 
          disabled={disabledDados ? disabledDados('academico') : false}
          InputProps={{ inputComponent: TextMaskCustom }}
        />
        <span id={`error-cont-numero${dados.index}`} className="error">Informar Número</span>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo Contato"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="tipoContato"
          value={dados.tipoContato}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={disabledDados ? disabledDados('academico') : false}
        >
          <option value="COMERCIAL">
            COMERCIAL
					</option>
          <option value="RESIDENCIAL">
            RESIDENCIAL
					</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="principal"
              color="primary"
              checked={dados.principal}
            />
          }
          label="Principal"
          name="principal"
          onChange={(e) => updateFieldPrincipal(e)}
          disabled={disabledDados ? disabledDados('academico') : false}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        {(disabledDados ? !disabledDados('academico') : false ) &&
          <Button fullWidth onClick={() => removerItem(dados.index)} color="secondary" variant="contained" size="small" startIcon={<DeleteIcon />}>Remover Contato</Button>
        }
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormContato