import React, { Component } from 'react'
import './TabelaPreco.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button } from '@material-ui/core'
import FormTabelaPreco from '../../components/forms/FormTabelaPreco'
import SaveIcon from '@material-ui/icons/Save';
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '../../components/Table'
import axios from 'axios'
import moment from 'moment';

const initalState = {
  tabelaPreco: {
    tabprec_id: "",
    tabprec_pessoa_id: "",
    tabprec_descricao: "",
    tabprec_data_inicio: "",
    tabprec_data_fim: "",
    itens: []
  },
  produto: {
    _id: "",
    prod_descricao: "",
    precprod_produto_id: "",
    precprod_valor_maximo: "",
    precprod_valor_minimo: "",
    precprod_ativo: true
  },
  cabecalhoTabela: [
    { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Produto' },
    { id: 'precprod_valor_minimo', numeric: false, disablePadding: false, label: 'Preço Mínimo' },
    { id: 'precprod_valor_maximo', numeric: false, disablePadding: false, label: 'Preço Máximo' },
    { id: 'precprod_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: ['editar', 'removeTabela'],
  tabelaPrecos: {
    list: []
  },
  produtos: {
    list: []
  },
  produtosRemovidos: {
    list: []
  },
  unidades: {
    list: []
  },
  unin_tipo: "",
  unidadesSelecionadas: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function mascaraValor(valor) {
  valor = valor.toString().replace(/\D/g, "");
  valor = valor.toString().replace(/(\d)(\d{8})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{5})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{2})$/, "$1,$2");
  return valor
}

export default class CadastroDepartamento extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'tabela-precos')[0]

    this.setState({
      permissoes
    })

    if (params.tabelaPrecoProdutoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    try {
      const { data: produtosAux } = await axios.get(`${process.env.REACT_APP_API_URL}/produtos`, this.getToken())
      const produtos = []
      produtosAux.forEach(produto => {
        if (produto.prod_ativo) {
          produtos.push({
            prod_id: produto.prod_id,
            mid_caminho: produto.midia.mid_caminho,
            prod_descricao: produto.prod_descricao,
            prod_unidademedida: produto.prod_unidademedida,
            subgrupprod_descricao: produto.subgrupoproduto.subgrupprod_descricao,
            subgrupprod_id: produto.subgrupoproduto.subgrupprod_id,
            grupprod_descricao: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
            prod_visibilidade: produto.prod_visibilidade,
            prod_ativo: produto.prod_ativo
          })
        }
      })

      this.setState({
        produtos: {
          list: produtos
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      tabelaPreco: {
        tabprec_id: "",
        tabprec_pessoa_id: "",
        tabprec_descricao: "",
        tabprec_data_inicio: "",
        tabprec_data_fim: "",
        itens: []
      },
      produto: {
        _id: "",
        prod_descricao: "",
        precprod_produto_id: "",
        precprod_valor_maximo: "",
        precprod_valor_minimo: "",
        precprod_ativo: true
      }
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    const dataHoje = new Date()
    const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

    const tabelaPreco = { ...this.state.tabelaPreco }
    tabelaPreco.tabprec_data_inicio = tabelaPreco.tabprec_data_inicio === "" ? dataHojeFormatado : tabelaPreco.tabprec_data_inicio
    tabelaPreco.tabprec_data_fim = tabelaPreco.tabprec_data_fim === "" ? dataHojeFormatado : tabelaPreco.tabprec_data_fim

    this.setState({ tabelaPreco })

    if (params.tabelaPrecoProdutoId) {
      try {
        const { data: tabelaPreco } = await axios.get(`${process.env.REACT_APP_API_URL}/tabelaPreco/${params.tabelaPrecoProdutoId}`, this.getToken())

        let itens = []

        for (let i = 0; i < tabelaPreco.itens.length; i++) {
          itens.push({
            _id: tabelaPreco.itens[i].produto.prod_id,
            precprod_id: tabelaPreco.itens[i].precprod_id,
            prod_descricao: tabelaPreco.itens[i].produto.prod_descricao,
            precprod_produto_id: tabelaPreco.itens[i].precprod_produto_id,
            precprod_valor_maximo: 'R$ ' + mascaraValor(tabelaPreco.itens[i].precprod_valor_maximo.toFixed(2)),
            precprod_valor_minimo: 'R$ ' + mascaraValor(tabelaPreco.itens[i].precprod_valor_minimo.toFixed(2)),
            precprod_ativo: tabelaPreco.itens[i].precprod_ativo,
            index: i + 1
          })

          let produtos = this.state.produtos.list

          const index2 = produtos.findIndex(param => param.prod_id === tabelaPreco.itens[i].precprod_produto_id)

          if (index2 !== -1) {
            const produtoRemovido = produtos[index2]

            const produtosRemovidos = this.state.produtosRemovidos.list

            produtosRemovidos.push(produtoRemovido)

            produtos.splice(index2, 1)

            this.setState({
              produtos: {
                list: produtos
              },
              produtosRemovidos: {
                list: produtosRemovidos
              }
            })
          }

        }

        const aux = {
          tabprec_id: tabelaPreco.tabprec_id,
          tabprec_pessoa_id: tabelaPreco.tabprec_pessoa_id,
          tabprec_descricao: tabelaPreco.tabprec_descricao,
          tabprec_data_inicio: moment(tabelaPreco.tabprec_data_inicio).format('YYYY-MM-DD'),
          tabprec_data_fim: moment(tabelaPreco.tabprec_data_fim).format('YYYY-MM-DD'),
          itens
        }

        this.setState({
          tabelaPreco: aux,
          unidadesSelecionadas: tabelaPreco.unidades.map(unidade => {
            return {
              unin_id: unidade.unin_id,
              unin_descricao: unidade.unin_descricao,
              unin_numero: unidade.unin_numero,
            }
          })
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar a Tabela de Preço, tente mais tarde"
        })

        this.backPage()
      }
    }

    if (unin_tipo === 'FRANQUEADO') {
      try {
        const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())

        this.setState({
          unidades: {
            list: unidades.map(unidade => {
              return {
                unin_id: unidade.unin_id,
                unin_descricao: unidade.unin_descricao,
                unin_numero: unidade.unin_numero,
              }
            })
          }
        })

      } catch (error) {
        console.log(error)
      }
    }


    this.setState({
      loading: false
    })

  }

  updateField(event) {
    const tabelaPreco = { ...this.state.tabelaPreco }
    tabelaPreco[event.target.name] = event.target.value
    this.setState({ tabelaPreco })
  }

  updateFieldProduto(event, value) {
    if (!value) {
      this.setState({
        produto: {
          _id: "",
          prod_descricao: "",
          precprod_produto_id: "",
          precprod_valor_maximo: "",
          precprod_valor_minimo: "",
          precprod_ativo: true
        }
      })
      return false
    }
    const produto = { ...this.state.produto }

    const aux = this.state.produtos.list.filter(param => param.prod_id === value.prod_id)

    produto.precprod_produto_id = value.prod_id
    produto._id = value.prod_id
    produto.prod_descricao = aux[0].prod_descricao
    this.setState({ produto })
  }

  updateFieldProdutoAtivo() {
    const produto = { ...this.state.produto }

    produto.precprod_ativo = !produto.precprod_ativo
    this.setState({ produto })
  }

  updateFieldProdutoPreco(event) {
    const produto = { ...this.state.produto }

    produto[event.target.name] = event.target.value
    this.setState({ produto })
  }

  updateAtivo(index) {
    const tabelaPreco = { ...this.state.tabelaPreco }
    tabelaPreco.itens[index - 1].precprod_ativo = !tabelaPreco.itens[index - 1].precprod_ativo
    this.setState({ tabelaPreco })
  }

  addItem() {

    if (!this.validateFormProduto()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios do Produto (*)."
      })
      return
    }

    const produto = this.state.produto
    const precoMinimo = parseInt(produto.precprod_valor_minimo.replace('R$ ', '').replace('.', ''))
    const precoMaximo = parseInt(produto.precprod_valor_maximo.replace('R$ ', '').replace('.', ''))

    if (precoMaximo < precoMinimo) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "O preço mínimo não pode ser superior ao preço máximo."
      })
      return
    }

    const tabelaPreco = this.state.tabelaPreco

    let produtos = this.state.produtos.list

    const itens = tabelaPreco.itens

    if (produto.index) {
      const index = tabelaPreco.itens.findIndex(param => param.index === produto.index)

      itens[index] = {
        _id: produto._id,
        precprod_id: produto.precprod_id,
        prod_descricao: produto.prod_descricao,
        precprod_produto_id: produto.precprod_produto_id,
        precprod_valor_maximo: produto.precprod_valor_maximo,
        precprod_valor_minimo: produto.precprod_valor_minimo,
        precprod_ativo: produto.precprod_ativo,
        index: produto.index
      }
    } else {
      const index = itens.length + 1

      itens.push({
        _id: produto._id,
        prod_descricao: produto.prod_descricao,
        precprod_produto_id: produto.precprod_produto_id,
        precprod_valor_maximo: produto.precprod_valor_maximo,
        precprod_valor_minimo: produto.precprod_valor_minimo,
        precprod_ativo: true,
        index
      })
    }

    tabelaPreco.itens = itens

    const index2 = produtos.findIndex(param => param.prod_id === produto.precprod_produto_id)

    if (index2 !== -1) {
      const produtoRemovido = produtos[index2]

      const produtosRemovidos = this.state.produtosRemovidos.list

      produtosRemovidos.push(produtoRemovido)

      produtos.splice(index2, 1)

      this.setState({
        produtos: {
          list: produtos
        },
        produtosRemovidos: {
          list: produtosRemovidos
        }
      })
    }

    this.setState({
      tabelaPreco,
      produto: initalState.produto,
      update: false
    })
  }

  removerItem(index) {
    const tabelaPreco = this.state.tabelaPreco
    const produtosRemovidos = this.state.produtosRemovidos.list
    const produtos = this.state.produtos.list

    let itens = tabelaPreco.itens

    const indexProdutoRemovido = produtosRemovidos.findIndex(param => param.prod_id === itens[index - 1].precprod_produto_id)

    const produtoAdd = produtosRemovidos[indexProdutoRemovido]

    produtos.push(produtoAdd)

    produtosRemovidos.splice(indexProdutoRemovido - 1, 1)

    itens.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        precprod_id: itens[i].precprod_id,
        prod_descricao: itens[i].prod_descricao,
        precprod_produto_id: itens[i].precprod_produto_id,
        precprod_valor_maximo: itens[i].precprod_valor_maximo,
        precprod_valor_minimo: itens[i].precprod_valor_minimo,
        precprod_ativo: itens[i].precprod_ativo,
        index: i + 1
      })
    }

    itens = aux

    tabelaPreco.itens = itens

    this.setState({
      tabelaPreco,
      produtosRemovidos: {
        list: produtosRemovidos
      },
      produtos: {
        list: produtos
      }
    })
  }

  editarItem(index) {
    const aux = this.state.tabelaPreco.itens.filter(param => param.index === index)

    this.setState({
      produto: aux[0],
      update: true
    })
  }

  backPage(timeout = 1000) {
    const self = this
    this.setState({
      tabelaPreco: {
        tabprec_id: "",
        tabprec_pessoa_id: "",
        tabprec_descricao: "",
        tabprec_data_inicio: "",
        tabprec_data_fim: "",
        itens: []
      },
      produto: {
        _id: "",
        prod_descricao: "",
        precprod_produto_id: "",
        precprod_valor_maximo: "",
        precprod_valor_minimo: "",
        precprod_ativo: true
      }
    })
    setTimeout(() => {
      self.props.history.push("/tabela_preco");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  updateFieldUnidade(event, value) {
    this.setState({
      unidadesSelecionadas: value
    })
  }

  validateFormProduto() {
    const { produto } = this.state

    if (!produto.precprod_produto_id) return false
    if (!produto.precprod_valor_maximo) return false
    if (!produto.precprod_valor_minimo) return false

    return true
  }

  validateForm() {
    const { tabelaPreco, unidadesSelecionadas } = this.state

    if (!tabelaPreco.tabprec_descricao) return false
    if (!tabelaPreco.tabprec_data_inicio) return false
    if (!tabelaPreco.tabprec_data_fim) return false

    tabelaPreco.itens.forEach(item => {
      if (!item.precprod_produto_id) return false
      if (!item.precprod_valor_maximo) return false
      if (!item.precprod_valor_minimo) return false
    });

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    if (unin_tipo === 'FRANQUEADO') {
      if (unidadesSelecionadas.length === 0) return false
    }

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })
    const tabelaPreco = this.state.tabelaPreco
    const unidadesSelecionadas = this.state.unidadesSelecionadas

    if (!this.validateForm()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const date1 = new Date(tabelaPreco.tabprec_data_inicio);
    const date2 = new Date(tabelaPreco.tabprec_data_fim);

    if (date1.getTime() > date2.getTime()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "A data inicial não pode ser maior que a data final."
      })
      return
    }

    const itens = tabelaPreco.itens
    let aux = []

    itens.forEach(item => {
      let precprod_valor_maximo = item.precprod_valor_maximo.replace("R$ ", "");
      precprod_valor_maximo = precprod_valor_maximo.replace(".", "");
      precprod_valor_maximo = precprod_valor_maximo.replace(",", ".");
      precprod_valor_maximo = parseFloat(precprod_valor_maximo)

      let precprod_valor_minimo = item.precprod_valor_minimo.replace("R$ ", "");
      precprod_valor_minimo = precprod_valor_minimo.replace(".", "");
      precprod_valor_minimo = precprod_valor_minimo.replace(",", ".");
      precprod_valor_minimo = parseFloat(precprod_valor_minimo)

      if (item.precprod_id !== "") {
        aux.push({
          precprod_id: item.precprod_id,
          precprod_produto_id: item.precprod_produto_id,
          precprod_valor_maximo: precprod_valor_maximo,
          precprod_valor_minimo: precprod_valor_minimo,
          precprod_ativo: item.precprod_ativo
        })
      } else {
        aux.push({
          precprod_produto_id: item.precprod_produto_id,
          precprod_valor_maximo: precprod_valor_maximo,
          precprod_valor_minimo: precprod_valor_minimo,
          precprod_ativo: item.precprod_ativo
        })
      }
    });

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
    const unin_id = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_id

    let unidades = []

    if (unin_tipo !== 'FRANQUEADO') {
      unidades = [
        {
          unin_id,
        }
      ]
    } else {
      unidades = unidadesSelecionadas
    }

    const dados = {
      tabprec_descricao: tabelaPreco.tabprec_descricao,
      tabprec_data_inicio: tabelaPreco.tabprec_data_inicio,
      tabprec_data_fim: tabelaPreco.tabprec_data_fim,
      unidades,
      itens: aux
    }

    try {
      if (tabelaPreco.tabprec_id !== "") {
        await axios.put(`${process.env.REACT_APP_API_URL}/tabelaPreco/${tabelaPreco.tabprec_id}`, dados, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/tabelaPreco`, dados, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })

      this.setState({
        tabelaPreco: {
          tabprec_id: "",
          tabprec_pessoa_id: "",
          tabprec_descricao: "",
          tabprec_data_inicio: "",
          tabprec_data_fim: "",
          itens: []
        },
        produto: {
          _id: "",
          prod_descricao: "",
          precprod_produto_id: "",
          precprod_valor_maximo: "",
          precprod_valor_minimo: "",
          precprod_ativo: true
        }
      })

      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  render() {
    const { tabelaPreco } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={11}>
              <h2 className="titulo">Cadastro de Tabela de Preço</h2>
            </Grid>
            <Grid item md={1}>
              {this.state.loadingSalvar &&
                <div>
                  <CircularProgress />
                </div>
              }
            </Grid>
          </Grid>
          <FormTabelaPreco
            updateField={e => this.updateField(e)}
            unidadesSelecionadas={this.state.unidadesSelecionadas}
            unin_tipo={this.state.unin_tipo}
            updateFieldProdutoPreco={(e) => this.updateFieldProdutoPreco(e)}
            updateFieldProduto={(e, value) => this.updateFieldProduto(e, value)}
            updateFieldProdutoAtivo={(e) => this.updateFieldProdutoAtivo(e)}
            dados={tabelaPreco}
            produtos={this.state.produtos.list}
            unidades={this.state.unidades.list}
            produto={this.state.produto}
            updateFieldItensAtivo={(index) => this.updateFieldItensAtivo(index)}
            addItem={(e) => this.addItem(e)}
            removeItem={(index) => this.removeItem(index)}
            update={this.state.update}
            updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)} />

          <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
            <Table
              updateAtivo={e => this.updateAtivo(e)}
              editar={e => this.editarItem(e)}
              remove={e => this.removerItem(e)}
              headCell={this.state.cabecalhoTabela}
              rows={this.state.tabelaPreco.itens}
              acoes={this.state.acoesTabela}
              noRemove={false}
            />
          </Grid>

          <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
            <Grid item md={9}></Grid>
            <Grid item md={3}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
            </Grid>
          </Grid>
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}