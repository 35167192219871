import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import real from './../../services/real'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import moment from 'moment'
import ModalErro from './../../components/modals/Erro'
import ModalRelatorio from './../../components/modals/Relatorios'
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Chart from "react-google-charts";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const initalState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
    data: '',
    filtrar_por: 'VENCIMENTO',
    cabecalhoTabela: []
  },
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm: {
        perm_visualizar: false
      }
    }]
  },
  contaFinanceiras: {
    list: []
  },
  contasSelecionadas: [],
  loading: false,
  urlView: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  ModalRelatorio: false,
  cabecalhoTabelaQtdAulas: [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
    { id: 'qtd_alunos2', numeric: false, disablePadding: false, label: 'Qtd. Alunos' }
  ],
  acoesTabelaQtdAulas: [],
  linhaSelecionada: '',
  unidades: {
    list: []
  },
  fluxo_caixa_simples: {
    list: []
  },
  saldo_anterior: 0,
  planosConta:{
    list: []
  },
  itensFechadoDre: [],
  tab: 0,
}

const maskData = value => {
  return value.replace(/\D/g, "").replace(/^(\d{2})(\d{4})+?$/, "$1/$2");
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default class Financeiro extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'rel-financeiro')

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.filter(param => param.unin_tipo === 'UNIDADE').sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : (b.unin_numero > a.unin_numero) ? -1 : 0).map(unidade => {
            
            return {
              id: unidade.unin_id,
              descricao: `${unidade.unin_numero} - ${unidade.unin_descricao}`,
              tipo: unidade.unin_tipo
            }
          })
        }
      })

      if(unidades.length === 1){
        const {filtro} = this.state
        filtro.unin_id = unidades[0].unin_id

        await this.consultarContas(unidades[0].unin_id)

        this.setState({filtro})
      }
    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: error.response ? error.response.data.message : 'Erro Interno'
      })
    }

    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      const planosConta = data.map(param => {
        return {
          _id: param.plancontas_id,
          plancontas_codigo: param.plancontas_codigo,
          plancontas_descricao: param.plancontas_descricao,
          plancontas_ativo: param.plancontas_ativo,
        }
      })
  
      this.setState({
        planosConta: {
          list: planosConta
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      relatorios_options: {
        list: permissoes.map(value => {
          return {
            tela_descricao: value.tela.tela_descricao,
            tela_slug: value.tela.tela_slug,
            perm: {
              perm_visualizar: value.perm_visualizar
            }
          }
        })
      }
    })
  }

  async consultarContas(unin_id){
    try {
      const dados = {
        filtro: {
          contfin_tipo_conta: "",
          contfin_descricao: "",
          contfin_ativo: "Sim",
        },
        unidadesnegocio: [unin_id]
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceiras = data.filter(param => param.contfin_ativo === true).sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(param => {
        return {
          _id: param.contfin_id,
          ordem: param.contfin_ordem,
          descricao: param.contfin_descricao
        }
      })

      this.setState({
        contaFinanceiras: {
          list: contaFinanceiras
        },
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  updateFieldConta(event, value){
    this.setState({
      contasSelecionadas: value
    })
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro

    let name = event.target.name
    let value = event.target.value

    if(name === 'data'){
      filtro[name] = maskData(value)
    }else{
      filtro[name] = value
    }

    if (filtro.relatorio !== 'fluxo-caixa-resumo') {
      if(name === 'data_inicial' || name === 'data_final'){
        filtro[name] = maskData(value)
      }
    }

    if(name === 'unin_id'){
      await this.consultarContas(parseInt(value))
    }

    if(name === 'relatorio'){
      filtro.data_inicial = ''
      filtro.data_final = ''
    }

    this.setState({ filtro })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loading: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio    

      if (filtro.unin_id === '') {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a unidade'
        })
        this.setState({
          loading: false
        })
        return
      }

      if (filtro.data_inicial === '') {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data inicial'
        })
        this.setState({
          loading: false
        })
        return
      }

      if (filtro.data_final === '') {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data final'
        })
        this.setState({
          loading: false
        })
        return
      }

      if (filtro.relatorio === 'resultado-consolidado') {
        // let mesAtual = moment().format('MM')
        // let anoAtual = moment().format('YYYY')
        
        // if(parseInt(filtro.data_final.replace('/','')) > parseInt(mesAtual+anoAtual)){
        //   this.handleOpenDialog({
        //     titulo: 'Ops...',
        //     descricao: 'Não é possivel consultar datas futuras.'
        //   })
        //   this.setState({
        //     loading: false
        //   })
        //   return
        // }
      }

      if (filtro.relatorio === 'fluxo-caixa-resumo') {
        const {contasSelecionadas} = this.state

        if(contasSelecionadas.length === 0){
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor selecionar uma conta'
          })
          this.setState({
            loading: false
          })
          return
        }

        filtro.conta_fin_ids = contasSelecionadas.map(conta => {return conta._id})
      }

      localStorage.setItem('filtro_relatorio', JSON.stringify(filtro))

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())

      if (filtro.relatorio === 'fluxo-caixa-resumo') {

        let dataChartFluxo = [['x', 'A Receber ', 'A Pagar ', 'Saldo']]

        for (const dia of data.datas) {
          dataChartFluxo.push(
            [
              moment(dia).format('DD/MM'), 
              parseFloat((data.list.filter(param => param.data === dia)[0].receber).toFixed(2)),
              parseFloat((data.list.filter(param => param.data === dia)[0].pagar).toFixed(2)) * -1,
              parseFloat((data.list.filter(param => param.data === dia)[0].saldo).toFixed(2)),
            ]
          )
        }

        this.setState({
          fluxo_caixa_simples: {
            list: data.list
          },
          dataChartFluxo,
          saldo_anterior: data.saldo_anterior
        })
      }else{
        this.setState({
          cabecalhoTabela: data.cabecalhoTabela,
          acoesTabela: data.acoesTabela,
          relatorios: {
            list: data.list
          },
          urlView: data.urlView
        })
      }


      this.setState({
        loading: false,
        tab: 0
      })

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }
  }

  handleCloseModalRelatorio() {
    this.setState({
     modalRelatorio: false,
     filtro: JSON.parse(localStorage.getItem('filtro_relatorio'))
    })
  }

  openRow(key){

    const {linhaSelecionada} = this.state

    this.setState({
      linhaSelecionada: linhaSelecionada === key  ? '' : key
    })
  }

  async imprimir() {
    const { filtro, relatorios, fluxo_caixa_simples, saldo_anterior } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      if (filtro.relatorio === 'fluxo-caixa-resumo') {
        filtro.list = fluxo_caixa_simples.list
        filtro.saldo_anterior = saldo_anterior
      }else{
        filtro.list = relatorios.list
      }

      filtro.cabecalhoTabela = this.state.cabecalhoTabela

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      console.log(data)

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      if (filtro.relatorio === 'fluxo-caixa-resumo')
        saveAs(pdfBlob, 'Fluxo-Caixa.pdf')
      
      if (filtro.relatorio === 'demonstracao-resultado-exercicio')
        saveAs(pdfBlob, 'DRE.pdf')

      if (filtro.relatorio === 'resultado-consolidado')
        saveAs(pdfBlob, 'Resultado-Consolidado.pdf')

    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }

  }

  minimizarMaximizarRows(row){
    const {itensFechadoDre} = this.state

    let codigo = row.split(' - ')[0]
    
    const index = itensFechadoDre.findIndex(param => param === codigo)
    
    if(index === -1){
      itensFechadoDre.push(codigo)
    }else{
      itensFechadoDre.splice(index, 1)
    }
    
    this.setState({
      itensFechadoDre
    })

  }

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  openContas(linha, index){
    const {relatorios} = this.state

    if((index !== (linha.length -1) && index !== 0)){
      let data = relatorios.list[0][index]

      let mes = data.split('/')[0]
  
      switch (mes) { 
        case 'jan':
         mes = '01';
         break;
        case 'fev':
         mes = '02';
         break;
        case 'mar':
         mes = '03';
         break;
        case 'abr':
         mes = '04';
         break;
        case 'mai':
         mes = '05';
         break;
        case 'jun':
         mes = '06';
         break;
        case 'jul':
         mes = '07';
         break;
        case 'ago':
         mes = '08';
         break;
        case 'set':
         mes = '09';
         break;
        case 'out':
         mes = '10';
         break;
        case 'nov':
         mes = '11';
         break;
        case 'dez':
         mes = '12';
         break;
        default:
          console.log('default');
      }
  
      let ano = data.split('/')[1]
  
      let plano = linha[0]
  
      let data_inicial = `${ano}-${mes}-01`
      let data_inicial_form = `${ano}/${mes}/01`
  
      var date = new Date(data_inicial_form);
      var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  
      let data_final = `${ano}-${mes}-${moment(ultimoDia).format('DD')}`
    
      let dados = {
        plano,
        data_inicial,
        data_final,
        filtrar_por: this.state.filtro.filtrar_por
      }
  
      localStorage.setItem('filtro_contas_rel_consolidado', JSON.stringify(dados));
      window.open('/conta_pagar_receber');
      return;
    }

   
  }

  render() {
    const { filtro, relatorios, loadingDownload, relatorios_options, 
      contaFinanceiras, contasSelecionadas, unidades, fluxo_caixa_simples, saldo_anterior, linhaSelecionada,
      planosConta, itensFechadoDre, tab, dataChartFluxo } = this.state
    
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Relatório Financeiro</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Relatório"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="relatorio"
                value={filtro.relatorio}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha o Relatório--</option>
                {relatorios_options.list.sort((a, b) => (a.tela_descricao > b.tela_descricao) ? 1 : ((b.tela_descricao > a.tela_descricao) ? -1 : 0)).map(value => {
                  if (value.perm) {
                    if (value.perm.perm_visualizar) {
                      return (
                        <option value={value.tela_slug}>{value.tela_descricao}</option>
                      )
                    }
                  }
                  return false
                })}
              </TextField>
            </Grid>

            {filtro.relatorio === "fluxo-caixa-resumo" &&
              <React.Fragment>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="unin_id"
                    value={filtro.unin_id}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Unidade</option>
                    {unidades.list.map(unidade => {
                      return (
                        <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type='date' label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={12} sm={6} id='form-contas'>
                    <Autocomplete
                      multiple
                      limitTags={1}
                      id="multiple-limit-tags"
                      options={contaFinanceiras.list.filter(conta => {
                        if (contasSelecionadas.findIndex(i => i._id === conta._id) === -1){
                          return conta;
                        }else{
                          return false
                        }
                      })}
                      size="small"
                      value={contasSelecionadas}
                      onChange={(event, value) => this.updateFieldConta(event, value)}
                      getOptionLabel={(option) => `${option.ordem} - ${option.descricao}`}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Contas" InputLabelProps={{ shrink: true }}/>
                      )}
                    />
                  </Grid>
              </React.Fragment>
            }
            {(filtro.relatorio === "demonstracao-resultado-exercicio" || filtro.relatorio === "resultado-consolidado") &&
              <React.Fragment>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="unin_id"
                    value={filtro.unin_id}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Unidade</option>
                    {unidades.list.map(unidade => {
                      return (
                        <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar por"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="filtrar_por"
                    value={filtro.filtrar_por}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="VENCIMENTO"> Data de Vencimento</option>
                    <option value="PAGAMENTO"> Data de Pagamento</option> 
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Data Incial (Mês/Ano)" placeholder='xx/xxxx' variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Data Final (Mês/Ano)" placeholder='xx/xxxx' variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.relatorio === '' ? true : false}>
                Filtrar
              </Button>
            </Grid>
            {filtro.relatorio === "fluxo-caixa-resumo" &&
              <React.Fragment>
                {fluxo_caixa_simples.list.length > 0 &&
                  <Grid item md={2} xs={12} sm={3}>
                    <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                      Imprimir
                    </Button>
                  </Grid>
                }
              </React.Fragment>
            }
            {relatorios.list.length > 0 &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {filtro.relatorio === "fluxo-caixa-resumo" &&
                <React.Fragment>
                  {fluxo_caixa_simples.list.length > 0 && 
                    <React.Fragment>
                      <AppBar position="static" className='mg_top_10'>
                        <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                          <Tab label="Relatório" {...a11yProps(0)} />
                          <Tab label="Grafico" {...a11yProps(1)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={tab} index={0}>
                        <React.Fragment>
                          <Grid container spacing={2} direction="row" className="mg_top_10">
                            <Grid item md={12} xs={12} sm={12}>
                              <h4 className="titulo">Saldo Inicial: <span style={{color: saldo_anterior < 0 ? '#FF0000' :  (saldo_anterior === 0 ? '#000' :'#006400')}}>R$ {real(saldo_anterior)}</span></h4>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} direction="row">
                            <Grid item md={12} xs={12} sm={12} id='fluxo_caixa' >
                              <table className="lista borderTable">
                                <thead>
                                  <tr className="titulos acompanha">
                                    <th style={{width:'10%', height: '23px'}}></th>
                                    <th style={{width:'18%'}}>Data</th>
                                    <th style={{width:'18%'}}>Pagar</th>
                                    <th style={{width:'18%'}}>Receber</th>
                                    <th style={{width:'18%'}}>Saldo do Dia</th>
                                    <th style={{width:'18%'}}>Saldo</th>
                                  </tr>
                                </thead>
                                <tbody className='rolagemTabela '>
                                    {fluxo_caixa_simples.list.map((fluxo, key) => {
                                      return (
                                        <React.Fragment key={key}>
                                          <tr style={{cursor: 'pointer'}} onClick={e => this.openRow(key)}>
                                            <td style={{width:'10%'}}>
                                              <IconButton color="primary" aria-label="view">
                                                <AddBoxIcon />
                                              </IconButton>
                                            </td>
                                            <td style={{marginTop: '5px', width:'18%'}}>{moment(fluxo.data).format('DD/MM/YYYY')}</td>
                                            <td style={{marginTop: '5px', width:'18%', color: fluxo.pagar < 0 ? '#FF0000' :  (fluxo.pagar === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.pagar)}</td>
                                            <td style={{marginTop: '5px', width:'18%', color: fluxo.receber < 0 ? '#FF0000' :  (fluxo.receber === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.receber)}</td>
                                            <td style={{marginTop: '5px', width:'18%', color: fluxo.saldo_dia < 0 ? '#FF0000' :  (fluxo.saldo_dia === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.saldo_dia)}</td>
                                            <td style={{marginTop: '5px', width:'18%', color: fluxo.saldo < 0 ? '#FF0000' :  (fluxo.saldo === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.saldo)}</td>
                                          </tr>
                                          <tr>
                                            <td colspan="6" style={{overflow: 'hidden', height: linhaSelecionada === key ? (fluxo.contas.length === 0 ? '35px' :'200px') : '20px', width:'100%'}} id={key}>
                                              {fluxo.contas.length === 0 &&
                                                <p style={{marginTop: linhaSelecionada === key ? '5px' : '30px', marginLeft: '2%', width: '96%'}}  >Nenhum registro para essa data!</p>
                                              }

                                              {fluxo.contas.length > 0 &&
                                                <table style={{marginTop: linhaSelecionada === key ? '5px' : '30px', marginLeft: '2%', width: '96%'}} className="lista borderTable">
                                                  <thead>
                                                    <tr className="titulos acompanha">
                                                      <th style={{width:'10%'}}>Doc.</th>
                                                      <th style={{width:'10%'}}>Tipo</th>
                                                      <th style={{width:'30%'}}>Cliente/Fornecedor</th>
                                                      <th style={{width:'10%'}}>Tipo de Data</th>
                                                      <th style={{width:'10%'}}>Status</th>
                                                      <th style={{width:'15%'}}>Form. Pagto.</th>
                                                      <th style={{width:'15%'}}>Valor</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className='rolagemTabelaParcelas'>
                                                    {fluxo.contas.map((conta, index) => {
                                                      
                                                      return (
                                                        <tr key={index}>
                                                          <td style={{width:'10%'}}>{conta.documento || ''}</td>
                                                          <td style={{width:'10%'}}>{conta.tipo === 'DEPOSITO' ? 'Receita' : (conta.tipo === 'RECEITA' ? 'Receita'  : 'Despesa') }</td>
                                                          <td style={{width:'30%'}}>
                                                            <p> {conta.pessoa_id ? (conta.tipo_pessoa === 'FISICA' ? conta.pesf_nome : conta.pesj_razaosocial) : ''}</p>
                                                            <p>{conta.descricao}</p>
                                                          </td>
                                                          <td style={{width:'10%'}}>{conta.tipo_data}</td>
                                                          <td style={{width:'10%'}}>{conta.status}</td>
                                                          <td style={{width:'15%'}}>{conta.forma_pagto || ''}</td>
                                                          <td style={{width:'15%', color: conta.novo_valor > 0 ? '#006400' :'#FF0000'}}>R$ {real(conta.novo_valor)}</td>
                                                        </tr>
                                                      )
                                                    })}
                                                  </tbody>
                                                </table>
                                              }                                            
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      </TabPanel>
                      <TabPanel value={tab} index={1}>
                        <React.Fragment>
                          <Grid container spacing={2} direction="row" className="mg_top_10">
                            <Grid item md={12} xs={12} sm={12}>
                              <Chart
                                chartType="AreaChart"
                                width="100%"
                                height='400px'
                                data={dataChartFluxo}
                                options={{
                                  // legend: 'none',
                                  chartArea: {width: '78%'},
                                  annotations: {
                                    1: {
                                      style: 'point'
                                    },
                                    2: {
                                      style: 'point'
                                    },
                                    3: {
                                      style: 'point'
                                    }
                                  },
                                  lineWidth: 3,
                                  pointSize: 5,
                                  hAxis : {
                                    gridlines : {
                                      count : 0
                                    }
                                  },
                                  colors: ['green', 'red', '#2c4e82']
                                }}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      </TabPanel>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {(filtro.relatorio === "demonstracao-resultado-exercicio" || filtro.relatorio === "resultado-consolidado") &&
                <React.Fragment>
                   {relatorios.list.length > 0 &&
                     <Grid container direction="row" spacing={1} className="mg_top_20">
                      <Grid item md={12} xs={12} sm={12} id='dre'>
                        <table className="mg_top_20 lista sempadding borderTable">
                          <thead>
                            <tr className="titulos acompanha">
                              {relatorios.list[0].map((value, i) => {
                                return (
                                  <th 
                                    style={
                                      {
                                        textAlign: i !== 0 ?  'right' : 'left',
                                        width: i === 0 ? '20%' : `${80 / (relatorios.list[0].length - 1)}%`, 
                                        textTransform: 'capitalize'
                                      }
                                    }
                                  >
                                    {value}
                                  </th>
                                )
                              })}
                            </tr>
                          </thead>
                          <tbody className='rolagemTabela'>
                            {relatorios.list.map((value, key) => {
                              if(key !== 0){
                                let planoLinha = null
                                let indexFechado = -1
                                let indexFechadoItem = -1
                                return (
                                  <tr 
                                    key={key}
                                    style={{
                                      backgroundColor: (value[0] === 'RESULTADO' || value[0] === 'TOTAL DESPESAS' || value[0] === 'TOTAL RECEITAS') ? '#C0C0C0' : '',
                                    }}
                                  >
                                    {value.map((linha, index) => {
                                      if(index === 0 && linha !== 'RESULTADO'){
                                        planoLinha = planosConta.list.filter(param => param.plancontas_codigo === linha.split(' - ')[0])[0]
                                        indexFechado = itensFechadoDre.findIndex(param => {
                                          let codigo = linha.split(' - ')[0]
              
                                          let codigo_div = codigo.split('.')
              
                                          if(codigo_div.length > 1){
                                            if(codigo === param){
                                              return false
                                            }else if(codigo_div[0] === param){
                                              return true
                                            }else if (`${codigo_div[0]}.${codigo_div[1]}` === param){
                                              return true
                                            }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}` === param){
                                              return true
                                            }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}.${codigo_div[3]}` === param){
                                              return true
                                            }else{
                                              return false
                                            }
              
                                          }else{
                                            return false
                                          }
                                        })
                                        indexFechadoItem = itensFechadoDre.findIndex(param => {
                                          let codigo = linha.split(' - ')[0]
              
                                          let codigo_div = codigo.split('.')
              
                                          if(codigo === param){
                                            if(codigo_div[0] === param){
                                              return true
                                            }else if (`${codigo_div[0]}.${codigo_div[1]}` === param){
                                              return true
                                            }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}` === param){
                                              return true
                                            }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}.${codigo_div[3]}` === param){
                                              return true
                                            }else{
                                              return false
                                            }
                                          }else{
                                            return false
                                          }
                                        })
                                      }
                                      
                                      return (
                                        <td>
                                          <p 
                                            style={{
                                              textAlign: index !== 0 ?  'right' : 'left',
                                              color: index !== 0 ? (linha > 0 ? 'green' : (linha < 0 ? 'red' : '#000')) : '#000',
                                              fontSize: (value[0] === 'RESULTADO' || value[0] === 'TOTAL DESPESAS' || value[0] === 'TOTAL RECEITAS') ? '14px' : '12px',
                                              fontWeight: (value[0] === 'RESULTADO' || value[0] === 'TOTAL DESPESAS' || value[0] === 'TOTAL RECEITAS') ? 'bold' : '',
                                            }}
                                          >
                                            <span
                                              style={{
                                                cursor:( index !== (value.length -1) && index !== 0) ? 'pointer': ''
                                              }}
                                              onClick={() => this.openContas(value, index)}
                                            >
                                              {index === 0 ? linha : `${real(linha)}`}
                                            </span>
                                          </p>
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              }
              
                              return false
                            })}
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalRelatorio
            open={this.state.modalRelatorio}
            handleClose={e => this.handleCloseModalRelatorio(e)}
            list={this.state.relatoriosModal.list}
            cabecalhoTabela={this.state.cabecalhoTabelaModal}
            acoesTabela={this.state.acoesTabelaModal}
            imprimir={e => this.imprimirSub(e)}
            loadingDownload={loadingDownload}
            urlView={this.state.urlViewModal}
            linhaSelecionada={this.state.linhaSelecionada}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}