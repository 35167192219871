import React, { Component } from 'react'
import './Funcao.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'

const initalState = {
  funcao: {

  },
  funcoes: {
    list: []
  },
  funcoesFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'cargo', numeric: false, disablePadding: false, label: 'Cargo' },
    { id: 'departamento', numeric: false, disablePadding: false, label: 'Departamento' },
    { id: 'nivel', numeric: true, disablePadding: false, label: 'Nivel' },
    { id: 'ativo', numeric: true, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    descricao: "",
    cargo: "",
    departamento: ""
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Funcao extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'funcao')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/funcoes/list`, dados, this.getToken())

      const funcoes = []

      data.sort((a, b) => (a.func_descricao > b.func_descricao) ? 1 : (b.func_descricao > a.func_descricao) ? -1 : 0).forEach(funcao => {
        if (funcao.func_descricao !== 'Administrador do Sistema') {
          funcoes.push({
            _id: funcao.func_id,
            descricao: funcao.func_descricao,
            cargo: funcao.cargo.car_descricao,
            departamento: funcao.cargo.departamento.dep_descricao,
            nivel: funcao.func_nivel,
            ativo: funcao.func_ativo
          })
        }
      })

      this.setState({
        funcoes: {
          list: funcoes
        },
        funcoesFiltrado: {
          list: funcoes
        },
        loading: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const funcoes = this.state.funcoes.list
    const filtro = this.state.filtro

    const list = funcoes.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      funcoesFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, funcoesFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Funções...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Funções</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/funcao/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Função
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Função" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Cargo" variant="outlined" size="small" name="cargo" value={filtro.cargo} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Departamento" variant="outlined" size="small" name="departamento" value={filtro.departamento} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/funcao/cadastro/" headCell={this.state.cabecalhoTabela} rows={funcoesFiltrado.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}