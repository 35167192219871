import moment from 'moment'
import React, { Component } from 'react'
import './Rematricula.scss'
import './../../main/ultil.scss'
import axios from 'axios'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

import ModalErro from './../../components/modals/Erro'
import ModalConfirmacao from './../../components/modals/Confirmacao'

const initalState = {
  pessoas: [],
  pessoa: null,
  search: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingPessoa: false,
  loadingSalvar: false,
  update: false,
  statusPessoa: null,
  statusShow: {
    'ALUNO_ATIVO': 'Essa pessoa possui um contrato de aluno em Vigência'
  },
  buscaPor: 'cpf',
  unin_tipo: 'UNIDADE',
  unidades: {
    list: []
  },
  transferencia:{
    tra_unin_destino_id: ''
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesOutraUnidade: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
}

function maskCpf(value) {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export default class NomaMatricula extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    const data = {
      unidadesnegocio: [pessoa.fisica.funcionario.contrato.contt_unidadetrabalho_id]
    }

    const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())
    const { data: unidadesSistema } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupo`, data, this.getToken())

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    const permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'nova-matricula')[0]
    const permissoesOutraUnidade = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'nova-matricula_outra_unidade')[0]

    if(permissoesOutraUnidade){
      this.setState({permissoesOutraUnidade})
    }

    this.setState({
      permissoes,
      unidades: {
        list: unidades
      },
      unidadesSistema:{
        list:unidadesSistema
      }
    })
  }

  async updateField(event) {
    const { buscaPor } = this.state

    if (event.target.name === 'buscaPor') {
      this.setState({
        search: '',
        buscaPor: event.target.value
      })
    }

    if (event.target.name === 'search') {
      if (buscaPor === 'cpf') {
        this.setState({ search: maskCpf(event.target.value) })
      } else if (buscaPor === 'matricula') {
        this.setState({ search: event.target.value })
      }
    }
  }

  async buscaPessoa() {
    const { search, buscaPor, transferencia, unin_tipo } = this.state

    if(search === ''){
      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Erro na Consulta!',
          descricao: 'Por favor informar o campo de pesquisa',
        }
      })
      return
    }

    if(unin_tipo !== 'UNIDADE'){
      if(transferencia.tra_unin_destino_id === ''){
        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro na Consulta!',
            descricao: 'Por favor selecionar a unidade',
          }
        })
        return
      }
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/search/${this.state.search}`, { buscaPor, busca: 'aluno' }, this.getToken())

      this.setState({ pessoa: data })
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Erro na Consulta!',
          descricao: response.data.message,
        }
      })
    }

  }

  statusPessoa() {
    const { pessoa } = this.state

    if (!pessoa.aluno) {
      return this.setState({ statusPessoa: 'APTO' })
    }

    if (pessoa.aluno) {
      if (pessoa.aluno.contrato.contm_ativo) {
        return this.setState({ statusPessoa: 'ALUNO_ATIVO' })
      }

      if (!pessoa.aluno.contrato.contm_ativo) {
        return this.setState({ statusPessoa: 'APTO' })
      }

      const fimVigencia = moment(pessoa.aluno.contrato.contm_data_fim_vigencia)
      const hoje = moment()

      if (fimVigencia.diff(hoje, 'days') <= 0) {
        return this.setState({ statusPessoa: 'ALUNO_ATIVO' })
      }
    }
  }

  async goMatricula() {
    const {pessoa, transferencia, permissoesOutraUnidade, unidadesSistema} = this.state

    const pessoaLogada = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoaLogada ? pessoaLogada.fisica.funcionario.contrato.unidadetrabalho.unin_tipo : 'UNIDADE'

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    let tra_unin_destino_id = ''

    if(unin_tipo !== 'UNIDADE'){
      tra_unin_destino_id = transferencia.tra_unin_destino_id
    }else{
      tra_unin_destino_id = dados.unidadesnegocio[0]
    }

    localStorage.setItem('tra_unin_destino_id',tra_unin_destino_id);

    if(pessoa.alunos.lenght > 0 && pessoa.alunos[0].contratos[0] && pessoa.alunos[0].contratos[0].contm_unin_id !== parseInt(tra_unin_destino_id)){
      
      const unidade_origem = unidadesSistema.list.filter(param => param.unin_id === parseInt(pessoa.alunos[0].contratos[0].contm_unin_id))[0]

      if(permissoesOutraUnidade.perm_inserir){
        this.setState({
          modalConfirmacaoOpen: true,
          modalConfirmacao: {
            mensagem: `Este aluno pertence a unidade ${unidade_origem.unin_numero} - ${unidade_origem.unin_descricao}, deseja enviar um solicitação de permissão para a rematricula?`,
          }
        })
      }else{
        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Opps!',
            descricao: 'Você não possui permissão para essa ação',
          }
        })
        return
      }
     
      return
    }else{
      // const encode = Buffer.from(`${this.state.pessoa.pesf_id}`).toString('base64')
      this.props.history.push(`/aluno/cadastro/${this.state.pessoa.pesf_id}/nova-matricula`);
    }

  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  updateFieldUnidade(event){
    const {transferencia} = this.state

    transferencia[event.target.name] = event.target.value

    this.setState({transferencia})
  }

  handleCloseConfirmacao(){
    this.setState({
      modalConfirmacaoOpen: false,
      modalConfirmacao: {
        mensagem: ''
      }
    })
  }

  async confirmarSolicitacao(value){
    if(value){

      const {pessoa, transferencia} = this.state

      try {

        const pessoaLogada = JSON.parse(localStorage.getItem('pessoa'))
        const unin_tipo = pessoaLogada ? pessoaLogada.fisica.funcionario.contrato.unidadetrabalho.unin_tipo : 'UNIDADE'    

        let tra_unin_destino_id = ''

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dadosUnidade = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.unin_id
          })
        }

        if(unin_tipo !== 'UNIDADE'){
          tra_unin_destino_id = transferencia.tra_unin_destino_id
        }else{
          tra_unin_destino_id = dadosUnidade.unidadesnegocio[0]
        }
  
        const dados = {
          tra_contrato_id: pessoa.alunos[0].contratos[0].contm_id,
          tra_unin_origem_id: pessoa.alunos[0].contratos[0].contm_unin_id,
          tra_unin_destino_id
        }
  
        await axios.post(`${process.env.REACT_APP_API_URL}/aluno/solicitaRematricula`, dados, this.getToken())
  
        this.setState({
          modalConfirmacaoOpen: false,
          modalConfirmacao: {
            mensagem: ''
          }
        })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Parabéns',
            descricao: `Solicitação enviada!`,
          }
        })
        return
      } catch ({response}) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Atenção!',
            descricao: response.data.message,
          }
        })
      }
    }else{
      this.setState({
        modalConfirmacaoOpen: false,
        modalConfirmacao: {
          mensagem: ''
        }
      })
    }
  }

  render() {
    const { search, buscaPor, unin_tipo, unidades, transferencia } = this.state

    console.log(unidades.list)

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <div id="noma-matricula" className="cadastro">
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={10}>
                <h2 className="titulo">Rematrícula</h2>
              </Grid>
              <Grid item md={2}>
                {this.state.loadingSalvar}
                {this.state.loadingSalvar &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>
            <div>
              <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Busca por*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="buscaPor"
                    value={buscaPor}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="cpf">
                      CPF
                    </option>
                    <option value="matricula">
                      Subscrição
                    </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  {buscaPor === 'cpf' &&
                    <TextField className="input" label="CPF*" variant="outlined" size="small" name="search" value={search} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
                  }
                  {buscaPor === 'matricula' &&
                    <TextField className="input" label="Subscrição*" variant="outlined" size="small" name="search" value={search} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
                  }
                </Grid>
                {unin_tipo !== 'UNIDADE' &&
                 <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade de destino"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tra_unin_destino_id"
                    value={transferencia.tra_unin_destino_id}
                    onChangeCapture={(e) => this.updateFieldUnidade(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <option key={0} value=''> Escolha uma unidade </option>
                    {unidades.list.filter(param => param.unin_status).sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : (b.unin_numero > a.unin_numero) ? -1 : 0).map(unidade => {
                      return (
                        <option key={unidade.unin_id} value={unidade.unin_id}>
                          {unidade.unin_numero} - {
                            unidade.pessoa.pes_tipo === 'FISICA' ?
                              unidade.pessoa.fisica.pesf_nome :
                              unidade.pessoa.juridica.pesj_nomefantasia
                          }
                        </option>
                      )
                    })}
                  </TextField>
                 </Grid>
                }
                <Grid item md={3} xs={12} sm={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => this.buscaPessoa(e)}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>

              {!this.state.loadingPessoa && this.state.pessoa &&
                <Grid
                  container
                  direction="row"
                  style={{ 'marginTop': '30px' }}
                >
                  <Grid item md={12} xs={12} sm={12}>
                    <h4>Dados:</h4>
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} style={{ 'marginTop': '10px' }}>
                    <p style={{ 'margin': '5px 0' }}>
                      <b>Nome:</b> {this.state.pessoa.pesf_nome}
                    </p>
                    <p style={{ 'margin': '5px 0' }}>
                      <b>CPF:</b> {this.state.pessoa.pesf_cpf}
                    </p>
                    <p style={{ 'margin': '5px 0' }}>
                      <b>Data de Nascimento:</b> {moment(this.state.pessoa.pesf_datanascimento).format('DD/MM/YYYY')}
                    </p>
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} style={{ 'marginTop': '70px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={e => this.goMatricula(e)}
                    >
                      Nova Matrícula
                    </Button>
                  </Grid>
                </Grid>
              }
            </div>

            <ModalConfirmacao open={this.state.modalConfirmacaoOpen} dados={this.state.modalConfirmacao} handleClose={e => this.handleCloseConfirmacao(e)} confirmar={e => this.confirmarSolicitacao(e)} />
            <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          </div>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}