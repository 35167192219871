import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import './Aluno.scss'
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom'


import Agenda from './View/Agenda'
import Aluno from './View/Aluno'
import Financeiro from './View/Financeiro'
import Historico from './View/Historico'
import AusenciaTemporaria from './View/AusenciaTemporaria'
import Observacoes from './View/Observacoes'
import Comissao from './View/Comissao'
import Contrato from './View/Contrato'
import Transferencia from './View/Transferencia'

import { Grid, Button, TextField } from '@material-ui/core'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  table: {
    minWidth: 650,
  }
}));

export default function SimpleTabs(props) {
  const classes = useStyles();

  const tabs = {
    agenda: 0,
    aluno: 1,
    contrato: 2,
    ausencia: 3,
    historico: 4,
    financeiro: 5,
    transferencia: 6,
    observacoes: 7,
    responsavel: 8,
    comissao: 9
  }

  const [value, setValue] = React.useState(tabs[props.params.tab] ? tabs[props.params.tab] : 0);

  const aluno = props.dadosAluno
  const responsavel = props.dadosResponsavel
  const updateFieldFiltro = props.updateFieldFiltro
  const recarregarPagina = props.recarregarPagina
  const filtro = props.filtro

  const handleChange = (event, newValue) => {
    const tabsUrl = {
      0: 'agenda',
      1: 'aluno',
      2: 'contrato',
      3: 'ausencia',
      4: 'historico',
      5: 'financeiro',
      6: 'transferencia',
      7: 'observacoes',
      8: 'responsavel',
      9: 'comissao'
    }

    setValue(newValue)
    props.history.push(`/aluno/view/${aluno.aluno_matricula}/${tabsUrl[newValue]}`);
    return;
  };

  return (
    <div className={classes.root} id="view">
      <AppBar position="static">
        <Tabs id="viewAluno" variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Agenda de Aulas" {...a11yProps(0)} />
          <Tab label="Dados do Aluno" {...a11yProps(1)} />
          <Tab label="Dados do Contrato" {...a11yProps(2)} />
          <Tab label="Ausência temporária" {...a11yProps(3)} />
          <Tab label="Histórico" {...a11yProps(4)} />
          <Tab label="Financeiro" {...a11yProps(5)} />
          <Tab label="Transferência" {...a11yProps(6)} />
          <Tab label="Observações" {...a11yProps(7)} />
          {props.dadosAluno.contrato.resp_fin &&
            <Tab label="Dados do Responsável Financeiro" {...a11yProps(8)} />
          }
          <Tab label="Honorários" {...a11yProps(9)} />
        </Tabs>
      </AppBar>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={3} sm={6} xs={3}>
          <TextField fullWidth size="small" variant="outlined" className="input" label="Num. Matricula" value={aluno.aluno_matricula} disabled />
        </Grid>
        <Grid item md={3} sm={6} xs={3}>
          <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={`${aluno.pessoaFisica.nome}${aluno.pessoaFisica.sobrenome ? ` ${aluno.pessoaFisica.sobrenome}` : ''}`} disabled />
        </Grid>
        <Grid item md={2} sm={6} xs={3}>
          <TextField fullWidth size="small" variant="outlined" className="input" label="Situação" value={aluno.contrato.status.stcontmat_situacao} disabled />
        </Grid>
        <Grid item md={2} xs={6} sm={3}>
          <TextField className="input" placeholder="Matricula" variant="outlined" size="small" name="matricula" value={filtro.matricula} onChange={(e) => updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item md={2} xs={5} sm={3}>
          <Button fullWidth variant="contained" color="primary" onClick={() => recarregarPagina()} disabled={filtro.matricula === '' ? true : false}> Filtrar</Button>
        </Grid>
      </Grid>
      <br />
      <hr />
      <TabPanel value={value} index={0}>
        {props.permissoesAgenda.perm_visualizar &&
          <React.Fragment>
            {aluno.contrato.status.stcontmat_situacao === 'APROVADO' &&
              <Agenda aluno={aluno} />
            }
            {aluno.contrato.status.stcontmat_situacao === 'REPROVADO' &&
              <h4>Esse aluno não está aprovado!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'INATIVO' &&
              <h4>Esse aluno não está com o contrato ativo no momento!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO' &&
              <h4>Esse aluno está aguardando a aprovação do contrato!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'TRANSFERIDO' &&
              <h4>Esse aluno foi transferido para outra unidade!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'CANCELADO' &&
              <h4>Esse aluno está com o contrato cancelado!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'ENCERRADO' &&
              <h4>Esse aluno está com o contrato encerrado!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'AGUARDANDO_TRANFERENCIA' &&
              <h4>Esse aluno está aguardando transferência!</h4>
            }
          </React.Fragment>
        }
        {!props.permissoesAgenda.perm_visualizar && 
          <h4>Você não tem permissão para visualizar essa tela!</h4>
        }
        
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="cardEndereco">
          <Aluno aluno={aluno} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Contrato aluno={aluno} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AusenciaTemporaria aluno={aluno} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Historico aluno={aluno} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Financeiro aluno={aluno} {...props.props} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        {aluno.contrato.status.stcontmat_situacao === 'APROVADO' &&
          <Transferencia aluno={aluno} {...props} />
        }
        {aluno.contrato.status.stcontmat_situacao === 'REPROVADO' &&
          <h4>Esse aluno não está aprovado!</h4>
        }
        {aluno.contrato.status.stcontmat_situacao === 'INATIVO' &&
          <h4>Esse aluno não está com o contrato ativo no momento!</h4>
        }
        {aluno.contrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO' &&
          <h4>Esse aluno está aguardando a aprovação do contrato!</h4>
        }
        {aluno.contrato.status.stcontmat_situacao === 'TRANSFERIDO' &&
          <h4>Esse aluno foi transferido para outra unidade!</h4>
        }
        {aluno.contrato.status.stcontmat_situacao === 'CANCELADO' &&
          <h4>Esse aluno está com o contrato cancelado!</h4>
        }
        {aluno.contrato.status.stcontmat_situacao === 'ENCERRADO' &&
          <h4>Esse aluno está com o contrato encerrado!</h4>
        }
        {aluno.contrato.status.stcontmat_situacao === 'AGUARDANDO_TRANFERENCIA' &&
          <h4>Esse aluno está aguardando transferência!</h4>
        }
      </TabPanel>
      <TabPanel value={value} index={7}>
        {props.permissoesObsTela.perm_visualizar &&
          <Observacoes aluno={aluno} {...props.props} {...props} />
        }
        {!props.permissoesObsTela.perm_visualizar && 
          <h4>Você não tem permissão para visualizar essa tela!</h4>
        }
      </TabPanel>
      <TabPanel value={value} index={8}>
        <div className="cardEndereco">
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={3} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={responsavel.pessoaFisica.nome} disabled />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Sobrenome" value={responsavel.pessoaFisica.sobrenome} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Social" value={responsavel.pessoaFisica.apelido} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={responsavel.pessoaFisica.cpf} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={responsavel.pessoaFisica.rg} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={responsavel.pessoaFisica.dataNascimento} disabled />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className="titulo">Endereços do Responsável: </h4>
            </Grid>
          </Grid>
          {responsavel.enderecos.map(endereco => {
            return (
              <div className="cardEndereco" key={endereco.index}>
                {endereco.principal &&
                  <h4>Endereço Principal:</h4>
                }
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.cep} disabled />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.rua} disabled />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.numero} disabled />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.complemento} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.bairro} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.cidade} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.estado} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.tipoEndereco} disabled />
                  </Grid>
                </Grid>
              </div>
            )
          })}
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className="titulo">Telefones do Responsável: </h4>
            </Grid>
          </Grid>
          {responsavel.contatos.map(telefone => {
            return (
              <div className="cardEndereco" key={telefone.index}>
                {telefone.principal &&
                  <h4>Telefone Principal:</h4>
                }
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.numero} disabled InputProps={{ inputComponent: TextMaskCustom }} />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tipoContato} disabled />
                  </Grid>
                </Grid>
              </div>
            )
          })}
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className="titulo">E-mails do Responsável: </h4>
            </Grid>
          </Grid>
          {responsavel.emails.map(email => {
            return (
              <div className="cardEndereco" key={email.index}>
                {email.principal &&
                  <h4>E-mail Principal:</h4>
                }
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.email} disabled />
                  </Grid>
                </Grid>
              </div>
            )
          })}
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={9} xs={false}></Grid>
            <Grid item md={3} xs={6} sm={6}>
              <Link to={`/aluno`}>
                <Button className="btnCadastrar" variant="contained" color="secondary">
                  Voltar
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Comissao aluno={aluno} {...props.props} />
      </TabPanel>
    </div>
  );
}
