import React, { Component } from 'react'
import './TabelaPreco.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment';

const initalState = {
  tabelaPreco: {

  },
  tabelaPrecos: {
    list: []
  },
  tabelaPrecosFiltrado: {
    list: []
  },
  loading: false,
  cabecalhoTabela: [
    { id: 'tabprec_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'tabprec_data_inicio', numeric: false, disablePadding: false, label: 'Data de Inicio', orderBy: 'data_inicioOrder' },
    { id: 'tabprec_data_fim', numeric: false, disablePadding: false, label: 'Data Final', orderBy: 'data_fimOrder' },
  ],
  acoesTabela: ['update'],
  filtro: {
    tabprec_descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class TabelaPreco extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'tabela-precos')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tabelaPreco`, this.getToken())

      const tabelaPrecos = data.map(param => {
        return {
          _id: param.tabprec_id,
          tabprec_descricao: param.tabprec_descricao,
          tabprec_data_inicio: moment(param.tabprec_data_inicio).format('DD/MM/YYYY'),
          tabprec_data_fim: moment(param.tabprec_data_fim).format('DD/MM/YYYY'),
          data_inicioOrder: moment(param.tabprec_data_inicio).format('YYYYMMDD'),
          data_fimOrder: moment(param.tabprec_data_fim).format('YYYYMMDD')
        }
      })

      this.setState({
        tabelaPrecos: {
          list: tabelaPrecos
        },
        tabelaPrecosFiltrado: {
          list: tabelaPrecos
        },
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const tabelaPrecos = this.state.tabelaPrecos.list
    const filtro = this.state.filtro

    const list = tabelaPrecos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      tabelaPrecosFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, tabelaPrecosFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Tabelas de Preço...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Tabelas de Preços</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/tabela_preco/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Tabela
								    </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="tabprec_descricao" value={filtro.tabprec_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabela} rows={tabelaPrecosFiltrado.list} acoes={this.state.acoesTabela} urlUpdate="/tabela_preco/cadastro/" />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}