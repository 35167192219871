import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import React, { Component } from 'react'
import './Unidade.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, TextField, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormPessoaJuridica from '../../components/forms/FormPessoaJuridica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'
import FormUsuario from '../../components/forms/FormUsuario'
import FormContratoFuncionario from '../../components/forms/FormContratoFuncionario'
import FormContaBancaria from '../../components/forms/FormContaBancaria'
import ModalErro from './../../components/modals/Erro'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios'
import { format } from 'cnpj';
import verificaForcaDaSenha from './../../services/verificaForcaSenha'

const cpf = require('node-cpf-cnpj');
const cnpj = require('node-cpf-cnpj')

const initalState = {
  unidade: {
    id: "",
    descricao: "",
    ativo: true,
    tipoPessoa: "FISICA",
    franqueado_id: "",
    unidadePrincipal: true,
    importarDadosFuncionario: true,
    num_unidade: '',
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: ""
    },
    pessoaJuridica: {
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: ""
    },
    enderecos: [{
      cep_id: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    }]
  },
  unidades: {
    list: []
  },
  franqueados: {
    list: []
  },
  funcionario: {
    id: "",
    dataAdmissao: "",
    dataDemissao: "",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contas: [{
      cb_agencia: "",
      cb_numero: "",
      cb_gerente: "",
      cb_chave_pix: "",
      cb_banco_id: "",
      index: 1,
      banco: {
        banco_id: "",
        banco_nome: "",
        banco_numero: "",
        banco_padraocosif: ""
      }
    }],
    usuario: {
      login: "",
      senha: "",
      repetirSenha: "",
      usu_perfil_id: ""
    },
    ativo: "",
  },
  bancos: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  perfil: {
    per_id: "",
    per_descricao: ""
  },
  perfils: {
    list: []
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  disableEnderecoFuncionario: true,
  enableDadosPessoaFuncionario: true,
  enableDadosEnderecoFuncionario: false,
  enableDadosEmailFuncionario: false,
  enableDadosContatoFuncionario: false,
  enableDadosFuncionarioFuncionario: false,
  enableDadosContratoFuncionario: false,
  enableDadosUsuarioFuncionario: false,
  enableDadosContaBancariaFuncionario: false,
  enableUpdate: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  showPassword: false,
  showPasswordRepetir: false,
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function formatarTelefone(str) {
  if (str.length === 11) {
    return str.replace(/(\d{2})?(\d{5})?(\d{4})/, "($1) $2-$3")
  } else if (str.length === 10) {
    return str.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3")
  } else {
    return ""
  }
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

export default class CadastroFranqueado extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'unidade')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_inserir) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    this.setState({
      unidade: {
        id: "",
        descricao: "",
        ativo: true,
        tipoPessoa: "FISICA",
        franqueado_id: "",
        unidadePrincipal: true,
        importarDadosFuncionario: true,
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          dataNascimento: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: ""
        },
        pessoaJuridica: {
          razaoSocial: "",
          nomeFantasia: "",
          cnpj: "",
          inscricaoEstadual: "",
          dataFundacao: ""
        },
        enderecos: [{
          cep_id: "",
          rua: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: "1"
        }],
        emails: [{
          email: "",
          principal: true,
          index: "1"
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: "1"
        }]
      },
      funcionario: {
        id: "",
        dataAdmissao: "",
        dataDemissao: "",
        contrato: {
          departamento: "",
          idDepartamento: "",
          cargo: "",
          idCargo: "",
          funcao: "",
          idFuncao: "",
          dataInicial: "",
          dataFinal: "",
          observacao: ""
        },
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          dataNascimento: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        contas: [{
          cb_agencia: "",
          cb_numero: "",
          cb_gerente: "",
          cb_chave_pix: "",
          cb_banco_id: "",
          index: 1,
          banco: {
            banco_id: "",
            banco_nome: "",
            banco_numero: "",
            banco_padraocosif: ""
          }
        }],
        usuario: {
          login: "",
          senha: "",
          repetirSenha: "",
          usu_perfil_id: ""
        },
        ativo: "",
      }
    })

    try {
      const { data: franqueadosAux } = await axios.get(`${process.env.REACT_APP_API_URL}/franqueados`, this.getToken())

      const franqueadosFisica = franqueadosAux.filter(u => u.pessoa.pes_tipo === "FISICA")
      const franqueadosJuridica = franqueadosAux.filter(u => u.pessoa.pes_tipo === "JURIDICA")

      const franqueados = []

      franqueadosFisica.forEach(pessoaFisica => {
        franqueados.push({
          _id: pessoaFisica.unin_id,
          franqueado: pessoaFisica.pessoa.fisica.pesf_nome,
        })
      });

      franqueadosJuridica.forEach(pessoaJuridica => {
        franqueados.push({
          _id: pessoaJuridica.unin_id,
          franqueado: pessoaJuridica.pessoa.juridica.pesj_nomefantasia,
        })
      });

      const { data: departamentos } = await axios.post(`${process.env.REACT_APP_API_URL}/departamentos/list`, {}, this.getToken())

      this.setState({
        franqueados: {
          list: franqueados
        },
        departamentos: {
          list: departamentos.filter(u => u.dep_ativo === true)
        },
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar a função, tente mais tarde"
      })

      this.backPage()
    }

    try {
      const { data: bancos } = await axios.get(`${process.env.REACT_APP_API_URL}/bancos`, this.getToken())

      this.setState({
        bancos: {
          list: bancos
        }
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Bancos, tente mais tarde"
      })

      this.backPage()
    }

    const unidade = { ...this.state.unidade }
    const funcionario = { ...this.state.funcionario }
    this.setState({ unidade, funcionario })
  }

  updateFieldPessoaFisica(event) {
    const unidade = { ...this.state.unidade }
    // document.getElementById(`error-pf-unidade-${event.target.name}`).style.display = "none";
    unidade.pessoaFisica[event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldPessoaJuridica(event) {
    const unidade = { ...this.state.unidade }
    // document.getElementById(`error-pj-unidade-${event.target.name}`).style.display = "none";
    unidade.pessoaJuridica[event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldEndereco(event, index) {
    const unidade = { ...this.state.unidade }
    // document.getElementById(`error-end-unidade-${event.target.name}${index}`).style.display = "none";
    unidade.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldEmail(event, index) {
    const unidade = { ...this.state.unidade }
    // document.getElementById(`error-ema-unidade-${event.target.name}${index}`).style.display = "none";
    unidade.emails[index - 1][event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldContato(event, index) {
    const unidade = { ...this.state.unidade }
    unidade.contatos[index - 1][event.target.name] = event.target.value
    // document.getElementById(`error-cont-unidade-${event.target.name}${index}`).style.display = "none";
    this.setState({ unidade })
  }

  async buscaPerfis() {

    let tipoUnidade = 'FRANQUEADO'

    let params = `visibilidade=${tipoUnidade}`

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?${params}`, this.getToken())

      this.setState(
        {
          perfis: {
            list: data
          }
        }
      )
    } catch (error) {
      console.log(error)
    }


  }

  formataTelefone(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const unidade = { ...this.state.unidade }
    if (!formatarTelefone(telefoneSemFormatacao)) {
      const erro = {
        titulo: "Campo Incorreto!",
        descricao: "O campo de telefone está incorreto!."
      }
      this.setState({
        modalErro: true,
        erro
      })
    }
    unidade.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)

    this.setState({ unidade })
  }

  formataTelefoneFuncionario(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const funcionario = { ...this.state.funcionario }

    if (!formatarTelefone(telefoneSemFormatacao)) {
      const erro = {
        titulo: "Campo Incorreto!",
        descricao: "O campo de telefone está incorreto!."
      }
      this.setState({
        modalErro: true,
        erro
      })
    }

    funcionario.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    this.setState({ funcionario })
  }

  async updateFieldUsuario(event) {
    const funcionario = { ...this.state.funcionario }
    funcionario.usuario[event.target.name] = event.target.value

    if (event.target.name === 'senha') {
      const resp = await verificaForcaDaSenha(event.target.value)
      this.setState({
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      })
    }

    this.setState({ funcionario })
  }

  updateField(event) {

    if (event.target.name === "tipoPessoa") {
      if (event.target.value === "FISICA") {
        this.setState({
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false
        })
      } else {
        this.setState({
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true
        })
      }
    }

    const unidade = { ...this.state.unidade }
    unidade[event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldUnidadePrincipal() {
    const unidade = { ...this.state.unidade }
    unidade.unidadePrincipal = !unidade.unidadePrincipal
    this.setState({ unidade })
  }

  updateFieldEnderecoPrincipal(event, index) {
    const unidade = { ...this.state.unidade }
    const indexAux = unidade.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      unidade.enderecos[indexAux].principal = false
    }

    unidade.enderecos[index - 1].principal = !unidade.enderecos[index - 1].principal
    this.setState({ unidade })
  }

  updateFieldContatoPrincipal(event, index) {
    const unidade = { ...this.state.unidade }
    const indexAux = unidade.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      unidade.contatos[indexAux].principal = false
    }

    unidade.contatos[index - 1].principal = !unidade.contatos[index - 1].principal
    this.setState({ unidade })
  }

  updateFieldEmailPrincipal(event, index) {
    const unidade = { ...this.state.unidade }
    const indexAux = unidade.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      unidade.emails[indexAux].principal = false
    }

    unidade.emails[index - 1].principal = !unidade.emails[index - 1].principal

    this.setState({ unidade })
  }

  updateFieldEnderecoPrincipalFuncionario(event, index) {
    const funcionario = { ...this.state.funcionario }
    const indexAux = funcionario.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      funcionario.enderecos[indexAux].principal = false
    }

    funcionario.enderecos[index - 1].principal = !funcionario.enderecos[index - 1].principal
    this.setState({ funcionario })
  }

  updateFieldContatoPrincipalFuncionario(event, index) {
    const funcionario = { ...this.state.funcionario }
    const indexAux = funcionario.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      funcionario.contatos[indexAux].principal = false
    }

    funcionario.contatos[index - 1].principal = !funcionario.contatos[index - 1].principal
    this.setState({ funcionario })
  }

  updateFieldEmailPrincipalFuncionario(event, index) {
    const funcionario = { ...this.state.funcionario }
    const indexAux = funcionario.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      funcionario.emails[indexAux].principal = false
    }

    funcionario.emails[index - 1].principal = !funcionario.emails[index - 1].principal

    this.setState({ funcionario })
  }

  updateFieldImportarDadosFuncionario(event) {
    const unidade = { ...this.state.unidade }
    unidade.importarDadosFuncionario = !unidade.importarDadosFuncionario
    this.setState({ unidade })
  }

  async validarCpfCnpj(event) {

    let cpf_cnpj = event.target.value
    const unidade = this.state.unidade

    if (unidade.tipoPessoa === "FISICA") {
      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {

        unidade.pessoaFisica.cpf = ""
        this.setState({ unidade })

        const erro = {
          titulo: "CPF Invalido!",
          descricao: "Por favor corrija o campo CPF"
        }
        this.setState({
          modalErro: true,
          erro
        })
      } else {
        unidade.pessoaFisica.cpf = cpf_cnpj
        this.setState({ unidade })
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        unidade.pessoaJuridica.cnpj = cpf_cnpj
        this.setState({ unidade })

      } else {
        unidade.pessoaJuridica.cnpj = ""
        this.setState({ unidade })
        const erro = {
          titulo: "CNPJ Invalido!",
          descricao: "Por favor corrija o campo CNPJ"
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }

    // if (cpf_cnpj.length <= 11) {

    // } else {

    // }
  }

  async validarCpfFuncionario(event) {

    let cpf_cnpj = event.target.value
    const funcionario = this.state.funcionario
    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {

      funcionario.pessoaFisica.cpf = ""
      this.setState({ funcionario })

      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
    } else {
      funcionario.pessoaFisica.cpf = cpf_cnpj
      this.setState({ funcionario })
    }
    // if (cpf_cnpj.length <= 11) {

    // } else {

    // }
  }

  async pegaEndereco(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const unidade = { ...this.state.unidade }
      const enderecos = unidade.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      unidade.enderecos[index - 1] = endereco
      this.setState({ unidade })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        unidade.enderecos[index - 1] = endereco
        this.setState({ unidade })

        document.getElementById(`error-end-rua${index}`).style.display = "none";

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        unidade.enderecos[index - 1] = endereco
        this.setState({ unidade })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  async pegaEnderecoFuncionario(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const funcionario = { ...this.state.funcionario }
      const enderecos = funcionario.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      funcionario.enderecos[index - 1] = endereco
      this.setState({ funcionario })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        funcionario.enderecos[index - 1] = endereco
        this.setState({ funcionario })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        funcionario.enderecos[index - 1] = endereco
        this.setState({ funcionario })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  addNovoEndereco() {
    const unidade = { ...this.state.unidade }
    const enderecos = unidade.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    unidade.enderecos = enderecos
    this.setState({ unidade })
  }

  addNovoContato() {
    const unidade = { ...this.state.unidade }
    const contatos = unidade.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: false,
      index
    })

    unidade.contatos = contatos
    this.setState({ unidade })
  }

  addNovoEmail() {
    const unidade = { ...this.state.unidade }
    const emails = unidade.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    unidade.emails = emails
    this.setState({ unidade })
  }

  proximo(atual, prox) {
    const { unidade, funcionario } = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      let valido = true
      if (unidade.descricao === "") {
        valido = false
        document.getElementById('error-unin-descricao').style.display = "block";
      }

      if (unidade.num_unidade === "") {
        valido = false
        document.getElementById('error-unin-num').style.display = "block";
      }

      if (unidade.tipoPessoa === "FISICA") {
        if (unidade.pessoaFisica.nome === "") {
          valido = false
          document.getElementById('error-pf-unidade-nome').style.display = "block";
        }
        if (unidade.pessoaFisica.cpf === "") {
          valido = false
          document.getElementById('error-pf-unidade-cpf').style.display = "block";
        }
        if (unidade.pessoaFisica.rg === "") {
          valido = false
          document.getElementById('error-pf-unidade-rg').style.display = "block";
        }
        if (unidade.pessoaFisica.rg_orgao_emissor === "") {
          valido = false
          document.getElementById('error-pf-unidade-rg_orgao_emissor').style.display = "block";
        }
        if (unidade.pessoaFisica.rg_uf === "") {
          valido = false
          document.getElementById('error-pf-unidade-rg_uf').style.display = "block";
        }
        if (unidade.pessoaFisica.rg_data_emissao === "") {
          valido = false
          document.getElementById('error-pf-unidade-rg_data_emissao').style.display = "block";
        }
        if (unidade.pessoaFisica.dataNascimento === "") {
          valido = false
          document.getElementById('error-pf-unidade-dataNascimento').style.display = "block";
        }

      } else {
        if (unidade.pessoaJuridica.razaoSocial === "") {
          valido = false
          document.getElementById('error-pj-unidade-razaoSocial').style.display = "block";
        }
        if (unidade.pessoaJuridica.nomeFantasia === "") {
          valido = false
          document.getElementById('error-pj-unidade-nomeFantasia').style.display = "block";
        }
        if (unidade.pessoaJuridica.cnpj === "") {
          valido = false
          document.getElementById('error-pj-unidade-cnpj').style.display = "block";
        }
        if (unidade.pessoaJuridica.inscricaoEstadual === "") {
          valido = false
          document.getElementById('error-pj-unidade-inscricaoEstadual').style.display = "block";
        }
        if (unidade.pessoaJuridica.dataFundacao === "") {
          valido = false
          document.getElementById('error-pj-unidade-dataFundacao').style.display = "block";
        }
      }

      if (valido) {
        this.setState({
          enableDadosPessoa: false,
          enableDadosEndereco: true
        })
      }
    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      unidade.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          valido = false
        }
        if (endereco.numero === "") {
          valido = false
        }
        if (endereco.rua === "") {
          valido = false
        }
        if (endereco.numero === "") {
          valido = false
        }
        if (endereco.rua === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEndereco: false,
          enableDadosContato: true
        })
      }
    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      unidade.contatos.forEach(contato => {
        if (!contato.numero) {
          valido = false
          var id = `error-cont-unidade-numero${contato.index}`
          document.getElementById(id).style.display = "block"
        }
      });

      this.buscaPerfis()

      if (valido) {
        this.setState({
          enableDadosContato: false,
          enableDadosEmail: true
        })
      }
    } else if (atual === "enableDadosEmail" && prox === "enableDadosFuncionario") {
      let valido = true
      unidade.emails.forEach(email => {
        if (email.email === "") {
          valido = false
          var id = `error-ema-unidade-email${email.index}`
          document.getElementById(id).style.display = "block"
        }
      });      

      if (valido) {
        this.setState({
          enableDadosEmail: false,
          enableDadosFuncionario: true
        })
      }
    }

    if (atual === "enableDadosPessoaFuncionario" && prox === "enableDadosEnderecoFuncionario") {
      let valido = true

      if (funcionario.pessoaFisica.nome === "") {
        valido = false
        document.getElementById('error-pf-funcionario-nome').style.display = "block";
      }
      if (funcionario.pessoaFisica.cpf === "") {
        valido = false
        document.getElementById('error-pf-funcionario-cpf').style.display = "block";
      }
      if (funcionario.pessoaFisica.rg === "") {
        valido = false
        document.getElementById('error-pf-funcionario-rg').style.display = "block";
      }
      if (funcionario.pessoaFisica.rg_orgao_emissor === "") {
        valido = false
        document.getElementById('error-pf-funcionario-rg_orgao_emissor').style.display = "block";
      }
      if (funcionario.pessoaFisica.rg_uf === "") {
        valido = false
        document.getElementById('error-pf-funcionario-rg_uf').style.display = "block";
      }
      if (funcionario.pessoaFisica.rg_data_emissao === "") {
        valido = false
        document.getElementById('error-pf-funcionario-rg_data_emissao').style.display = "block";
      }
      if (funcionario.pessoaFisica.dataNascimento === "") {
        valido = false
        document.getElementById('error-pf-funcionario-dataNascimento').style.display = "block";
      }

      if (valido) {
        this.setState({
          enableDadosPessoaFuncionario: false,
          enableDadosEnderecoFuncionario: true
        })
      }

    } else if (atual === "enableDadosEnderecoFuncionario" && prox === "enableDadosContatoFuncionario") {
      let valido = true
      funcionario.enderecos.forEach(endereco => {
        let id = null
        if (endereco.cep === "") {
          valido = false
          id = `error-end-funcionario-cep${endereco.index}`
          document.getElementById(id).style.display = "block"
        }
        if (endereco.numero === "") {
          id = `error-end-funcionario-numero${endereco.index}`
          document.getElementById(id).style.display = "block"
          valido = false
        }
        if (endereco.rua === "") {
          id = `error-end-funcionario-rua${endereco.index}`
          document.getElementById(id).style.display = "block"
          valido = false
        }
        if (endereco.numero === "") {
          valido = false
        }
        if (endereco.rua === "") {
          valido = false
        }
      });

      if (valido) {
        this.setState({
          enableDadosEnderecoFuncionario: false,
          enableDadosContatoFuncionario: true
        })
      }
    } else if (atual === "enableDadosContatoFuncionario" && prox === "enableDadosEmailFuncionario") {
      let valido = true
      funcionario.contatos.forEach(contato => {
        if (!contato.numero) {
          valido = false
          var id = `error-cont-funcionario-numero${contato.index}`
          document.getElementById(id).style.display = "block"
        }
      });
      if (valido) {
        this.setState({
          enableDadosContatoFuncionario: false,
          enableDadosEmailFuncionario: true
        })
      }
    } else if (atual === "enableDadosEmailFuncionario" && prox === "enableDadosContratoFuncionario") {
      let valido = true
      funcionario.emails.forEach(email => {
        if (email.email === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEmailFuncionario: false,
          enableDadosContratoFuncionario: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosContratoFuncionario" && prox === "enableDadosContaBancariaFuncionario") {
      let valido = true

      if (funcionario.funcao === "") {
        valido = false
      }
      if (funcionario.dataInicial === "") {
        valido = false
      }

      this.buscaPerfis()

      if (valido) {
        this.setState({
          enableDadosContratoFuncionario: false,
          enableDadosContaBancariaFuncionario: true,
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosContaBancariaFuncionario" && prox === "enableDadosUsuarioFuncionario") {
      this.setState({
        enableDadosContaBancariaFuncionario: false,
        enableDadosUsuarioFuncionario: true
      })
    }
  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosFuncionario") {
      this.setState({
        enableDadosEmail: true,
        enableDadosFuncionario: false
      })
    }

    if (anterior === "enableDadosPessoaFuncionario" && atual === "enableDadosEnderecoFuncionario") {
      this.setState({
        enableDadosPessoaFuncionario: true,
        enableDadosEnderecoFuncionario: false
      })
    } else if (anterior === "enableDadosEnderecoFuncionario" && atual === "enableDadosContatoFuncionario") {
      this.setState({
        enableDadosEnderecoFuncionario: true,
        enableDadosContatoFuncionario: false
      })
    } else if (anterior === "enableDadosContatoFuncionario" && atual === "enableDadosEmailFuncionario") {
      this.setState({
        enableDadosContatoFuncionario: true,
        enableDadosEmailFuncionario: false
      })
    } else if (anterior === "enableDadosEmailFuncionario" && atual === "enableDadosContratoFuncionario") {
      this.setState({
        enableDadosEmailFuncionario: true,
        enableDadosContratoFuncionario: false,
      })
    } else if (anterior === "enableDadosContratoFuncionario" && atual === "enableDadosContaBancariaFuncionario") {
      this.setState({
        enableDadosContratoFuncionario: true,
        enableDadosContaBancariaFuncionario: false,
      })
    } else if (anterior === "enableDadosContaBancariaFuncionario" && atual === "enableDadosUsuarioFuncionario") {
      this.setState({
        enableDadosContaBancariaFuncionario: true,
        enableDadosUsuarioFuncionario: false,
      })
    }

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/unidade");
    }, timeout)
  }

  updateFieldPessoaFisicaFuncionario(event) {
    const funcionario = { ...this.state.funcionario }
    funcionario.pessoaFisica[event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldEnderecoFuncionario(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldEmailFuncionario(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.emails[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldContatoFuncionario(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  async updateFieldContratoFuncionario(event) {
    if (!event.target === undefined) return false
    const funcionario = { ...this.state.funcionario }

    if (event.target && event.target.name === "idDepartamento") {
      if (funcionario.contrato.departamento === parseInt(event.target.value)) return false

      try {
        funcionario.contrato.idDepartamento = parseInt(event.target.value)
        funcionario.contrato.departamento = this.state.departamentos.list.filter(u => u.dep_id === parseInt(event.target.value))[0].dep_descricao

        const { data: cargos } = await axios.get(`${process.env.REACT_APP_API_URL}/cargos/departamento/${parseInt(event.target.value)}`, this.getToken())

        this.setState({
          funcionario,
          cargosFiltrado: {
            list: cargos.filter(u => u.car_ativo === true)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (event.target && event.target.name === "idCargo") {
      if (funcionario.contrato.cargo === parseInt(event.target.value)) return false

      try {
        funcionario.contrato.idCargo = parseInt(event.target.value)
        funcionario.contrato.cargo = this.state.cargosFiltrado.list.filter(u => u.car_id === parseInt(event.target.value))[0].car_descricao

        const { data: funcoes } = await axios.get(`${process.env.REACT_APP_API_URL}/funcoes/cargo/${parseInt(event.target.value)}`, this.getToken())

        this.setState({
          funcionario,
          funcaosFiltrado: {
            list: funcoes.filter(u => u.func_ativo === true)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (event.target && event.target.name === "idFuncao") {
      if (funcionario.contrato.funsao === parseInt(event.target.value)) return false

      funcionario.contrato.idFuncao = parseInt(event.target.value)
      funcionario.contrato.funcao = this.state.funcaosFiltrado.list.filter(u => u.func_id === parseInt(event.target.value))[0].func_descricao
      this.setState({ funcionario })
    }

    if (event.target && event.target.name === "observacao") {
      funcionario.contrato.observacao = event.target.value
      this.setState({ funcionario })
    }

    if (event.target && event.target.name === "dataInicial") {
      funcionario.contrato.dataInicial = event.target.value
      this.setState({ funcionario })
    }
  }

  updateFieldFuncionario(event) {
    const funcionario = { ...this.state.funcionario }
    funcionario[event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  addNovoEnderecoFuncionario() {
    const funcionario = { ...this.state.funcionario }
    const enderecos = funcionario.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    funcionario.enderecos = enderecos
    this.setState({ funcionario })
  }

  addNovoContatoFuncionario() {
    const funcionario = { ...this.state.funcionario }
    const contatos = funcionario.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: false,
      index
    })

    funcionario.contatos = contatos
    this.setState({ funcionario })
  }

  addNovoEmailFuncionario() {
    const funcionario = { ...this.state.funcionario }
    const emails = funcionario.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    funcionario.emails = emails
    this.setState({ funcionario })
  }

  addNovaContaBancariaFuncionario() {
    const funcionario = { ...this.state.funcionario }
    const contas = funcionario.contas

    const index = contas.length + 1

    contas.push({
      cb_agencia: "",
      cb_numero: "",
      cb_gerente: "",
      cb_chave_pix: "",
      cb_banco_id: "",
      banco: {
        banco_nome: "",
        banco_numero: "",
        banco_padraocosif: ""
      },
      index
    })

    funcionario.contas = contas
    this.setState({ funcionario })
  }

  updateFieldContaBancariaFuncionario(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.contas[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldBanco(event, value, index) {
    if (!value) return false

    const funcionario = { ...this.state.funcionario }
    funcionario.contas[index - 1].banco = value
    funcionario.contas[index - 1].cb_banco_id = value.banco_id
    this.setState({ funcionario })
  }

  removeEnderecoUnidade(index) {
    const unidade = { ...this.state.unidade }
    const enderecos = unidade.enderecos
    const aux = enderecos

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    unidade.enderecos = array
    this.setState({ unidade })
  }

  removeContatoUnidade(index) {
    const unidade = { ...this.state.unidade }
    const contatos = unidade.contatos
    const aux = contatos

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    unidade.contatos = array
    this.setState({ unidade })
  }

  removeEmailUnidade(index) {
    const unidade = { ...this.state.unidade }
    const emails = unidade.emails
    const aux = emails

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    unidade.emails = array
    this.setState({ unidade })
  }

  removeEnderecoFuncionario(index) {
    const funcionario = { ...this.state.funcionario }
    const enderecos = funcionario.enderecos
    const aux = enderecos

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    funcionario.enderecos = array
    this.setState({ funcionario })
  }

  removeContatoFuncionario(index) {
    const funcionario = { ...this.state.funcionario }
    const contatos = funcionario.contatos
    const aux = contatos

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    funcionario.contatos = array
    this.setState({ funcionario })
  }

  removeEmailFuncionario(index) {
    const funcionario = { ...this.state.funcionario }
    const emails = funcionario.emails
    const aux = emails

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    funcionario.emails = array
    this.setState({ funcionario })
  }

  removerConta(index) {
    const funcionario = { ...this.state.funcionario }
    const contas = funcionario.contas
    const aux = contas

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    funcionario.contas = array
    this.setState({ funcionario })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFieldPerfil(event, value) {
    if (!value) return false

    const funcionario = { ...this.state.funcionario }
    funcionario.usuario.usu_perfil_id = value.per_id

    this.setState({
      funcionario,
      perfil: {
        per_id: value.per_id,
        per_descricao: value.per_descricao
      }
    })

  }

  async salvar() {
    const { unidade, funcionario, professor, aulasSeleciondas } = this.state
    let valido = true

    if (unidade.unidadePrincipal) {
      if (funcionario.usuario.login === "") {
        valido = false
      }

      if (funcionario.usuario.senha !== "" && funcionario.usuario.repetirSenha === "") {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      if (funcionario.usuario.senha !== funcionario.usuario.repetirSenha) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Senhas não Conferem!",
            descricao: "Por favor verificar as senhas."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      if (!validacaoEmail(funcionario.usuario.login)) {
        this.handleOpenDialog({
          titulo: 'Opps',
          descricao: 'Email Invalido!'
        })
        return
      }
    } else {
      unidade.emails.forEach(email => {
        if (email.email === "") {
          valido = false
        }
      });
    }

    if (valido) {

      let fisica = {}
      let juridica = {}

      if (unidade.tipoPessoa === "FISICA") {
        fisica = {
          nome: unidade.pessoaFisica.nome,
          cpf: cpf.strip(unidade.pessoaFisica.cpf),
          rg: unidade.pessoaFisica.rg,
          datanascimento: unidade.pessoaFisica.dataNascimento,
          rg_orgao_emissor: unidade.pessoaFisica.rg_orgao_emissor,
          rg_uf: unidade.pessoaFisica.rg_uf,
          rg_data_emissao: unidade.pessoaFisica.rg_data_emissao,
        }
      } else {
        juridica = {
          razaosocial: unidade.pessoaJuridica.razaoSocial,
          nomefantasia: unidade.pessoaJuridica.nomeFantasia,
          inscricaoestadual: unidade.pessoaJuridica.inscricaoEstadual,
          datafundacao: unidade.pessoaJuridica.dataFundacao,
          cnpj: cnpj.strip(unidade.pessoaJuridica.cnpj)
        }
      }

      let enderecos = []
      let emails = []
      let telefones = []

      unidade.enderecos.forEach(endereco => {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: endereco.complemento === "" ? "N/A" : endereco.complemento,
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      });

      unidade.emails.forEach(email => {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      });

      unidade.contatos.forEach(telefone => {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      });

      let responsavel = {}

      let dados = {}

      if (unidade.unidadePrincipal) {
        if (unidade.tipoPessoa === "FISICA") {
          responsavel = {
            usuario: {
              usu_perfil_id: funcionario.usuario.usu_perfil_id,
              login: funcionario.usuario.login,
              senha: funcionario.usuario.senha
            }
          }
        } else {

          let auxProfessor = {}

          if (funcionario.contrato.cargo === "Professor") {
            auxProfessor = {
              ...professor,
              aulas: aulasSeleciondas.map(aula => {
                return {
                  aula_id: aula.aula_id
                }
              })
            }

            responsavel = {
              nome: funcionario.pessoaFisica.nome,
              cpf: funcionario.pessoaFisica.cpf,
              rg: funcionario.pessoaFisica.rg,
              rg_uf: funcionario.pessoaFisica.rg_uf,
              rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
              rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
              datanascimento: funcionario.pessoaFisica.dataNascimento,
              unidadetrabalho_id: funcionario.unidadetrabalho_id,
              contt_funcao_id: funcionario.contrato.idFuncao,
              fun_data_admissao: funcionario.contrato.dataInicial,
              datainicio: funcionario.contrato.dataInicial,
              observacao: funcionario.contrato.observacao,
              professor: auxProfessor,
              enderecos,
              emails,
              telefones,
              usuario: {
                usu_perfil_id: funcionario.usuario.usu_perfil_id,
                login: funcionario.usuario.login,
                senha: funcionario.usuario.senha
              }
            }
          }else{
            responsavel = {
              nome: funcionario.pessoaFisica.nome,
              cpf: funcionario.pessoaFisica.cpf,
              rg: funcionario.pessoaFisica.rg,
              rg_uf: funcionario.pessoaFisica.rg_uf,
              rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
              rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
              datanascimento: funcionario.pessoaFisica.dataNascimento,
              unidadetrabalho_id: funcionario.unidadetrabalho_id,
              contt_funcao_id: funcionario.contrato.idFuncao,
              fun_data_admissao: funcionario.contrato.dataInicial,
              datainicio: funcionario.contrato.dataInicial,
              observacao: funcionario.contrato.observacao,
              enderecos,
              emails,
              telefones,
              usuario: {
                usu_perfil_id: funcionario.usuario.usu_perfil_id,
                login: funcionario.usuario.login,
                senha: funcionario.usuario.senha
              }
            }
          }
          
          const contas = funcionario.contas
          let contasAux = []

          contas.forEach(conta => {
            if (conta.cb_agencia !== "" && conta.cb_numero !== "" && conta.cb_banco_id !== "") {

              const aux = {
                numero: conta.cb_numero,
                gerente: conta.cb_gerente,
                agencia: conta.cb_agencia,
                chave_pix: conta.cb_chave_pix,
                banco_id: conta.cb_banco_id
              }

              if (conta.cb_gerente === "") {
                delete aux.gerente
              }

              if (conta.cb_chave_pix === "") {
                delete aux.chave_pix
              }

              contasAux.push(aux)
            }
          });

          if (contasAux.length !== 0) {
            responsavel.contas = contasAux
          }
        }

        dados = {
          franqueado_id: unidade.franqueado_id,
          unidade: {
            tipo: unidade.tipoPessoa,
            descricao: unidade.descricao,
            num_unidade: unidade.num_unidade,
            juridica,
            fisica,
            enderecos,
            emails,
            telefones
          },
          responsavel
        }
      } else {
        dados = {
          franqueado_id: unidade.franqueado_id,
          unidade: {
            tipo: unidade.tipoPessoa,
            descricao: unidade.descricao,
            num_unidade: unidade.num_unidade,
            juridica,
            fisica,
            enderecos,
            emails,
            telefones
          }
        }
      }

      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/unidades`, dados, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })

        this.setState({
          unidade: {
            id: "",
            descricao: "",
            ativo: true,
            tipoPessoa: "FISICA",
            franqueado_id: "",
            unidadePrincipal: true,
            importarDadosFuncionario: true,
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            pessoaJuridica: {
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: ""
            },
            enderecos: [{
              cep_id: "",
              rua: "",
              numero: "",
              complemento: "",
              bairro: "",
              cidade: "",
              estado: "",
              cep: "",
              principal: true,
              tipoEndereco: "RESIDENCIAL",
              index: "1"
            }],
            emails: [{
              email: "",
              principal: true,
              index: "1"
            }],
            contatos: [{
              numero: "",
              tipoContato: "RESIDENCIAL",
              principal: true,
              index: "1"
            }]
          },
          funcionario: {
            id: "",
            dataAdmissao: "",
            dataDemissao: "",
            contrato: {
              departamento: "",
              idDepartamento: "",
              cargo: "",
              idCargo: "",
              funcao: "",
              idFuncao: "",
              dataInicial: "",
              dataFinal: "",
              observacao: ""
            },
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            enderecos: [{
              rua: "",
              complemento: "",
              numero: "",
              bairro: "",
              cidade: "",
              estado: "",
              cep: "",
              principal: true,
              tipoEndereco: "RESIDENCIAL",
              index: 1
            }],
            emails: [{
              email: "",
              principal: true,
              index: 1
            }],
            contatos: [{
              numero: "",
              tipoContato: "RESIDENCIAL",
              principal: true,
              index: 1
            }],
            usuario: {
              login: "",
              senha: "",
              repetirSenha: "",
              usu_perfil_id: ""
            },
            ativo: "",
          }
        })

        this.backPage()

      } catch ({ response }) {
        if (response.status === 400) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.handleOpenDialog({})
      }

    } else {
      if (funcionario.usuario.senha !== funcionario.usuario.repetirSenha) {
        const erro = {
          titulo: "Senhas não Conferem!",
          descricao: "Por favor verificar as senhas."
        }
        this.setState({
          modalErro: true,
          erro
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleClickShowPasswordRepetir() {
    this.setState({
      showPasswordRepetir: !this.state.showPasswordRepetir
    })
  }

  async verificaNumUnidade(event){
    const {unidade} = this.state

    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/unidades/verificaNum/${parseInt(event.target.value)}`, this.getToken())
    
      if(data){
        unidade.num_unidade = ''
        const erro = {
          titulo: "Número Invalido!",
          descricao: "O Número informado já pertence a outra unidade."
        }
        this.setState({
          modalErro: true,
          erro,
          unidade
        })
      }
    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

    
  }

  render() {
    const { unidade, funcionario, franqueados, enableUpdate } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={12}>
              <h2 className="titulo">Cadastro de Unidade</h2>
            </Grid>
          </Grid>
          {this.state.enableDadosPessoa &&
            <div className="formFranqueado">
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade Primária (Principal)*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="unidadePrincipal"
                    value={unidade.unidadePrincipal}
                    onChangeCapture={(e) => this.updateFieldUnidadePrincipal(e)}
                  >
                    <option value={true}>
                      Sim
											</option>
                    <option value={false}>
                      Não
											</option>
                  </TextField>
                </Grid>
                {!unidade.unidadePrincipal &&
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Franqueado Pertencente*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="franqueado_id"
                      onChangeCapture={(e) => this.updateField(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value="">
                        Selecionar Franqueado
											</option>
                      {franqueados.list.map(franqueado => {
                        return (
                          <option key={franqueado._id} value={franqueado._id}>
                            {franqueado.franqueado}
                          </option>
                        )
                      })}

                    </TextField>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={1} xs={12} sm={6}>
                  <TextField className="input" type='number' label="Num. Unidade" variant="outlined" size="small" name="num_unidade" value={unidade.num_unidade} onBlur={(e) => this.verificaNumUnidade(e)} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
                  <span id='error-unin-num' className="error">Informar Número da Unidade</span>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Descrição Unidade" variant="outlined" size="small" name="descricao" value={unidade.descricao} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
                  <span id='error-unin-descricao' className="error">Informar Descrição Unidade</span>
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid
                container
                spacing={1}
                direction="row"
                className="mg_top_20"
              >
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tipo Pessoa*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tipoPessoa"
                    value={unidade.tipoPessoa}
                    onChangeCapture={(e) => this.updateField(e)}
                  >
                    <option value="FISICA">
                      Pessoa Física
											</option>
                    <option value="JURIDICA">
                      Pessoa Júridica
											</option>
                  </TextField>
                </Grid>
              </Grid>
              {this.state.enableDadosPessoaFisica &&
                <FormPessoaFisica form='unidade' validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaFisica(e)} dados={unidade.pessoaFisica} />
              }

              {this.state.enableDadosPessoaJuridica &&
                <FormPessoaJuridica form='unidade' validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaJuridica(e)} dados={unidade.pessoaJuridica} />
              }
              <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                </Grid>
              </Grid>
            </div>
          }

          {this.state.enableDadosEndereco &&
            <div className="formFranqueado">
              <Grid container direction="row">
                <Grid item md={12}>
                  <h4>Dados de Endereço</h4>
                </Grid>
              </Grid>
              {unidade.enderecos.map(endereco => {
                return (
                  <div key={endereco.index} className="cardEndereco">
                    <FormEndereco form='unidade' removerItem={(index) => this.removeEnderecoUnidade(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEndereco(e, endereco.index)} updateField={e => this.updateFieldEndereco(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldEnderecoPrincipal(e, endereco.index)} dados={endereco} />
                  </div>
                )
              })}
              <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                <Grid item md={4}>
                  <Button fullWidth onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                </Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                </Grid>
              </Grid>
            </div>
          }

          {this.state.enableDadosContato &&
            <div className="formFranqueado">
              <Grid container direction="row">
                <Grid item md={12}>
                  <h4>Dados de Contato</h4>
                </Grid>
              </Grid>
              {unidade.contatos.map(contato => {
                return (
                  <div key={contato.index} className="cardEndereco">
                    <FormContato
                      form='unidade'
                      removerItem={(index) => this.removeContatoUnidade(index)}
                      updateField={e => this.updateFieldContato(e, contato.index)}
                      dados={contato}
                      updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)}
                      formataTelefone={e => this.formataTelefone(e, contato.index)} />
                  </div>
                )
              })}
              <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                <Grid item md={4}>
                  <Button fullWidth onClick={() => this.addNovoContato()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                </Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                </Grid>
              </Grid>
            </div>
          }

          {this.state.enableDadosEmail &&
            <div className="formFranqueado">
              <Grid container direction="row">
                <Grid item md={12}>
                  <h4>Dados de E-mail</h4>
                </Grid>
              </Grid>
              {unidade.emails.map(email => {
                return (
                  <div key={email.index} className="cardEndereco">
                    <FormEmail form='unidade' removerItem={(index) => this.removeEmailUnidade(index)} updateField={e => this.updateFieldEmail(e, email.index)} dados={email} updateFieldPrincipal={e => this.updateFieldEmailPrincipal(e, email.index)} />
                  </div>
                )
              })}
              <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                <Grid item md={4}>
                  <Button fullWidth onClick={() => this.addNovoEmail()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                </Grid>
                <Grid item md={3}>
                  {unidade.unidadePrincipal &&
                    <Button fullWidth onClick={() => this.proximo("enableDadosEmail", "enableDadosFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                  }
                  {!unidade.unidadePrincipal &&
                    <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                  }
                </Grid>
              </Grid>
            </div>
          }

          {this.state.enableDadosFuncionario &&
            <div className="formFranqueado">
              {unidade.tipoPessoa === "FISICA" &&
                <React.Fragment>
                  <Grid container direction="row" className='mg_top_10'>
                    <Grid item md={12}>
                      <h4>Cadastro de Usuário (Franqueado)</h4>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_20">
                    <Grid item md={4} xs={12} sm={6}>
                      <Autocomplete
                        onChange={(event, value) => this.updateFieldPerfil(event, value)}
                        freeSolo
                        options={this.state.perfis.list}
                        value={this.state.perfil}
                        getOptionLabel={option => option.per_descricao}
                        renderInput={(params) => (
                          <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={this.state.perfil.per_descricao} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <FormUsuario 
                    updateField={e => this.updateFieldUsuario(e)} 
                    dados={funcionario.usuario} 
                    handleClickShowPassword={e => this.handleClickShowPassword(e)}
                    handleClickShowPasswordRepetir={e => this.handleClickShowPasswordRepetir(e)}
                    showPassword={this.state.showPassword}
                    showPasswordRepetir={this.state.showPasswordRepetir}
                    nivelSenha={this.state.nivelSenha}
                    corSenha={this.state.corSenha}/>
                  <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={6}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosFuncionario", "enableDadosEmail")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      {!unidade.importarDadosFuncionario &&
                        <Button fullWidth onClick={() => this.proximo("enableDadosEmail", "enableDadosFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                      }
                      {unidade.importarDadosFuncionario &&
                        <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                      }
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {unidade.tipoPessoa === "JURIDICA" &&
                <React.Fragment>
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h4>Cadastro do Funcionário Responsável - Franqueado</h4>
                    </Grid>
                  </Grid>
                  {this.state.enableDadosPessoaFuncionario &&
                    <div className="formFranqueado">
                      <FormPessoaFisica form='funcionario' validarCpfCnpj={e => this.validarCpfFuncionario(e)} updateField={e => this.updateFieldPessoaFisicaFuncionario(e)} dados={funcionario.pessoaFisica} />
                      <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                        <Grid item md={6}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosFuncionario", "enableDadosEmail")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosPessoaFuncionario", "enableDadosEnderecoFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }

                  {this.state.enableDadosEnderecoFuncionario &&
                    <div className="formFranqueado">
                      <Grid container direction="row">
                        <Grid item md={12}>
                          <h4>Dados de Endereço</h4>
                        </Grid>
                      </Grid>
                      {funcionario.enderecos.map(endereco => {
                        return (
                          <div key={endereco.index} className="cardEndereco">
                            <FormEndereco form='funcionario' removerItem={(index) => this.removeEnderecoFuncionario(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEnderecoFuncionario(e, endereco.index)} updateField={e => this.updateFieldEnderecoFuncionario(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldEnderecoPrincipalFuncionario(e, endereco.index)} dados={endereco} />
                          </div>
                        )
                      })}
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={4}>
                          <Button fullWidth onClick={() => this.addNovoEnderecoFuncionario()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosEnderecoFuncionario", "enableDadosPessoaFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosEnderecoFuncionario", "enableDadosContatoFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }

                  {this.state.enableDadosContatoFuncionario &&
                    <div className="formFranqueado">
                      <Grid container direction="row">
                        <Grid item md={12}>
                          <h4>Dados de Contato</h4>
                        </Grid>
                      </Grid>
                      {funcionario.contatos.map(contato => {
                        return (
                          <div key={contato.index} className="cardEndereco">
                            <FormContato form='funcionario' formataTelefone={e => this.formataTelefoneFuncionario(e, contato.index)} removerItem={(index) => this.removeContatoFuncionario(index)} updateField={e => this.updateFieldContatoFuncionario(e, contato.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipalFuncionario(e, contato.index)} dados={contato} />
                          </div>
                        )
                      })}
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={4}>
                          <Button fullWidth onClick={() => this.addNovoContatoFuncionario()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosContatoFuncionario", "enableDadosEnderecoFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosContatoFuncionario", "enableDadosEmailFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }

                  {this.state.enableDadosEmailFuncionario &&
                    <div className="formFranqueado">
                      <Grid container direction="row">
                        <Grid item md={12}>
                          <h4>Dados de E-mail</h4>
                        </Grid>
                      </Grid>
                      {funcionario.emails.map(email => {
                        return (
                          <div key={email.index} className="cardEndereco">
                            <FormEmail form='funcionario' removerItem={(index) => this.removeEmailFuncionario(index)} updateField={e => this.updateFieldEmailFuncionario(e, email.index)} updateFieldPrincipal={e => this.updateFieldEmailPrincipalFuncionario(e, email.index)} dados={email} />
                          </div>
                        )
                      })}
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={4}>
                          <Button fullWidth onClick={() => this.addNovoEmailFuncionario()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosEmailFuncionario", "enableDadosContatoFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosEmailFuncionario", "enableDadosContratoFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {this.state.enableDadosContratoFuncionario &&
                    <div className="formFranqueado">
                      <Grid container direction="row">
                        <Grid item md={12}>
                          <h4>Dados do Contrato</h4>
                        </Grid>
                      </Grid>
                      <FormContratoFuncionario
                        form='funcionario'
                        enableUpdate={enableUpdate}
                        updateField={e => this.updateFieldContratoFuncionario(e)}
                        dados={funcionario.contrato}
                        departamentos={this.state.departamentos.list}
                        cargos={this.state.cargosFiltrado.list}
                        funcaos={this.state.funcaosFiltrado.list}
                      />
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={4}>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosContratoFuncionario", "enableDadosEmailFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosContratoFuncionario", "enableDadosContaBancariaFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }

                  {this.state.enableDadosContaBancariaFuncionario &&
                    <div className="formFranqueado">
                      <Grid container direction="row">
                        <Grid item md={12}>
                          <h4>Dados das Contas Bancarias</h4>
                        </Grid>
                      </Grid>
                      {funcionario.contas.map(conta => {
                        return (
                          <div key={conta.index} className="cardEndereco">
                            <FormContaBancaria
                              form='funcionario'
                              updateField={e => this.updateFieldContaBancariaFuncionario(e, conta.index)}
                              updateFieldBanco={(event, value, index) => this.updateFieldBanco(event, value, conta.index)}
                              dados={conta}
                              banco={conta.banco}
                              bancos={this.state.bancos.list}
                              removerConta={(index) => this.removerConta(index)} />
                          </div>
                        )
                      })}
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={4}>
                          <Button fullWidth onClick={() => this.addNovaContaBancariaFuncionario()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Nova Conta </Button>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosContaBancariaFuncionario", "enableDadosContratoFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosContaBancariaFuncionario", "enableDadosUsuarioFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }

                  {this.state.enableDadosUsuarioFuncionario &&
                    <div className="formFranqueado">
                      <Grid container direction="row">
                        <Grid item md={12}>
                          <h4>Dados do Usuário</h4>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={1} className="mg_top_20">
                        <Grid item md={4} xs={12} sm={6}>
                          <Autocomplete
                            onChange={(event, value) => this.updateFieldPerfil(event, value)}
                            freeSolo
                            options={this.state.perfis.list}
                            value={this.state.perfil}
                            getOptionLabel={option => option.per_descricao}
                            renderInput={(params) => (
                              <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={this.state.perfil.per_descricao} />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <FormUsuario 
                        form='funcionario'
                        updateField={e => this.updateFieldUsuario(e)} 
                        dados={funcionario.usuario}
                        handleClickShowPassword={e => this.handleClickShowPassword(e)}
                        handleClickShowPasswordRepetir={e => this.handleClickShowPasswordRepetir(e)}
                        showPassword={this.state.showPassword}
                        showPasswordRepetir={this.state.showPasswordRepetir}
                        nivelSenha={this.state.nivelSenha}
                        corSenha={this.state.corSenha} />
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={6}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosUsuarioFuncionario", "enableDadosContaBancariaFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
                </React.Fragment>
              }
            </div>
          }
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}